import clsx from "clsx";
import { ReactElement } from "react";
import "./PdfViewer.css";

type Props = {
  showTitle: boolean;
  title?: string;
  className?: string;
  pdfUrl: string;
};
const PdfViewer = ({ showTitle, title, className, pdfUrl }: Props): ReactElement => {
  const viewerSectionClassName = clsx(className, "pdf-section");
  return (
    <section id="pdf" className={viewerSectionClassName}>
      {showTitle && (
        <div style={{ marginBottom: "0px" }} className="content-title">
          {title}
        </div>
      )}
      <div style={{ position: "relative", paddingTop: "max(60%,326px)", height: "0", width: "100%" }}>
        <iframe
          title={title?.toLowerCase()}
          allow="clipboard-write"
          sandbox="allow-top-navigation allow-top-navigation-by-user-activation allow-downloads allow-scripts allow-same-origin allow-popups allow-modals allow-popups-to-escape-sandbox"
          allowFullScreen={true}
          className="pdf-style"
          src={pdfUrl}
        ></iframe>
      </div>
    </section>
  );
};

export default PdfViewer;
