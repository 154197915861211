import React from "react";
import Checkbox from "@mui/material/Checkbox";

import styles from "./Checkbox.module.scss";
import clsx from "clsx";

type Props = {
  label: string;
  onChange?: (e: any) => void;
  isChecked?: boolean;
  id?: string;
  className?: string;
};

const CheckboxComponent = ({ label, onChange, id, isChecked, className: classNameProp }: Props) => {
  return (
    <div className={clsx(styles.checkboxContainer, classNameProp)}>
      <Checkbox
        id={id}
        checked={isChecked}
        onChange={onChange}
        sx={{
          color: "#fff",
          "&.Mui-checked": {
            color: "#fff",
          },
        }}
      />
      <span className={styles.checkboxLabel}>{label}</span>
    </div>
  );
};

export default CheckboxComponent;
