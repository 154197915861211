import { ReactElement } from "react";
import { Route, BrowserRouter as Router, Routes, Navigate } from "react-router-dom";
import OS3Page from "ui/OriginStories/OS3Page";
import OS4Page from "ui/OriginStories/OS4Page";
import PublicReadComponent from "ui/ReadComics/PublicRead/PublicReadComponent";
import Navbar from "../components/Navbar/Navbar";
import ApeTownPage from "../ui/EliteApeTown/ApeTownPage";
import Landing from "../ui/Landing";
import { TheComics } from "ui/consts";
import useData from "context/dataContext";
import ForbiddenPage from "ui/common/ForbiddenPage";
import ReadOS4Comic from "ui/ReadComics/ReadOS4Comic";

type WithHeaderProps = {
  header?: boolean;
  transparent?: boolean;
  children: ReactElement | ReactElement[];
};

const WithHeader = ({ header = false, transparent, children }: WithHeaderProps): ReactElement => (
  <>
    {header && <Navbar transparent={transparent} />}
    {children}
  </>
);

type AppProps = {
  live: boolean;
};

const AppRoutes = ({ live }: AppProps): ReactElement => {
  const { isWalletSuspicious } = useData();

  return (
    <Router>
      <WithHeader header={false}>
        <Routes>
          <Route path="*" element={isWalletSuspicious && <Navigate to="/forbidden" replace />} />
        </Routes>
      </WithHeader>

      <WithHeader header={true}>
        <Routes>
          <Route path="/" element={<Landing live={live} />} />
          <Route path="/forbidden" element={<ForbiddenPage />} />
          <Route path="/punks3" element={<Landing live={live} />} />
          <Route path="/apes-out-on-the-town" element={<ApeTownPage />} />
          <Route path="/read-your-comic/origin-stories-4" element={<ReadOS4Comic />} />
          {Object.values(TheComics).map((comic: any): any => {
            return (
              <Route
                path={`/read-your-comic/${comic.slug}`}
                element={<PublicReadComponent title={comic.title} issuuLink={comic.issuuLink} />}
              />
            );
          })}
        </Routes>
      </WithHeader>

      <WithHeader header={true} transparent={true}>
        <Routes>
          <Route path="/os3" element={<OS3Page />} />
          <Route path="/os4" element={<OS4Page />} />
        </Routes>
      </WithHeader>
    </Router>
  );
};

export default AppRoutes;
