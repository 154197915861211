import SectionComponent from "../components/SectionComponent/SectionComponent";
import PdfViewer from "../components/PdfViewer/PdfViewer";
import PhaseSectionComponent from "../components/PhaseSection/PhaseSectionComponent";
import FooterSection from "../components/FooterSection/FooterSection";
import mainEdition from "../images/desktop/punks3_cover.jpg";
import collectorEdition from "../images/punks3_special_cover.png";
import holoEdition from "../images/punks3_holo.png";

type LandingProps = {
  live: boolean;
};

const Landing: React.FC<LandingProps> = ({ live }) => {
  const punks3 = [
    {
      title: "Main",
      imgSrc: mainEdition,
      quantity: "15,000 NFTS",
    },
    {
      title: "Collector's",
      imgSrc: collectorEdition,
      quantity: "1,500 NFTS",
    },
    {
      title: "Holo/Signed",
      imgSrc: holoEdition,
      quantity: "150 NFTS",
    },
  ];

  return (
    <>
      <SectionComponent hasButton={live} sectionName="glitch" />
      <SectionComponent sectionName="orb" />
      <SectionComponent sectionName="cores" hasButton={live} />
      <PhaseSectionComponent live={live} />
      <section className="punks3">
        <div className="punks3-section">
          <div className="content-title">PUNKS 3 EDITIONS</div>
          <div className="punks3-row">
            {punks3.map(item => (
              <div className="punks3-column">
                <img src={item.imgSrc} alt="" />
                <div className="punks3-edition-title">
                  <span>Punks 3: </span>
                  {item.title}
                  <div className="punks3-edition-title" style={{ marginTop: "0" }}>
                    Edition
                  </div>
                  <span style={{ color: "#FF3C88" }}>{item.quantity}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <PdfViewer
        showTitle={true}
        title={"READ PUNK 3"}
        pdfUrl={
          "https://e.issuu.com/embed.html?backgroundColor=%230c1121&backgroundColorFullscreen=%230c1121&d=punks_3_main&hideIssuuLogo=true&hideShareButton=true&u=pixelvault"
        }
      />
      <FooterSection />
    </>
  );
};

export default Landing;
