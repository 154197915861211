import "./FooterSection.css";
import Twitter from "images/Twitter.webp";
import Discord from "images/Discord.webp";
import PunksLogo from "images/PunksLogo.png";
import PixelVaultLogo from "images/PixelVaultLogo.svg";

const FooterSection: React.FC = () => {
  return (
    <footer>
      <div className="footer-container">
        <a href="https://punkscomic.com/">
          <img className="footer-punks-logo" src={PunksLogo} alt="The Punks Logo" />
        </a>
        <h4 className="footer-title">Join The Conversation</h4>
        <div className="social-links">
          <a style={{ marginRight: "20px" }} href="https://twitter.com/punkscomic">
            <img className="social-icons" src={Twitter} alt="twitter icon" />
          </a>
          <a href="https://discord.com/invite/pixelvault">
            <img className="social-icons" src={Discord} alt="discord icon" />
          </a>
        </div>
        <div className="links-container">
          <a className="footer-links footer-menu" href="https://punkscomic.com/meetthepunks.html">
            The Punks
          </a>
          <a className="footer-links footer-menu" href="https://punkscomic.com/the-comics.html">
            The Comics
          </a>
          <a className="footer-links footer-menu" href="https://punkscomic.com/faq.html">
            FAQS
          </a>
        </div>
        <a href="https://pixelvault.com/">
          <img className="footer-pv-logo" src={PixelVaultLogo} alt="The Pixel Vault Logo" />
        </a>

        <div className="footer-legal-container">
          <span className="footer-text">All Rights Reserved</span>
          <div className="legal-docs">
            <a
              style={{ textDecoration: "underline" }}
              className="footer-links"
              href="https://drive.google.com/file/d/1-75HaHsa5g-iGPtUoMlz-JcyRTPTGdF1/view?usp=sharing"
            >
              Terms Of Use
            </a>
            <a
              style={{ textDecoration: "underline" }}
              className="footer-links"
              href="https://drive.google.com/file/d/1-6AgzCeOC7y1JdwZv9RQHOQ_mrBDKp29/view?usp=sharing"
            >
              Privacy Policy
            </a>
          </div>
          <div className="contacts-container">
            <span className="footer-text">
              Contact Us At&nbsp;
              <a className="footer-links" href="mailto:info@pixelvault.com">
                Info@PixelVault.com
              </a>
            </span>
            <span className="footer-text">
              Report Bugs At&nbsp;
              <a className="footer-links" href="mailto:dev@pixelvault.com">
                Dev@PixelVault.com
              </a>
            </span>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterSection;
