import clsx from "clsx";
import "./ProgressBarComponent.css";

type ProgressBarProps = {
  minted: number;
  total: number;
  color?: string;
  barColor?: string;
  showRemaining?: boolean;
  className?: string;
};

const ProgressBarComponent: React.FC<ProgressBarProps> = props => {
  const { minted, total, color, barColor: bgColor, showRemaining: showBarStats = true, className } = props;

  const progressFillStyle = {
    width: `${(minted / total) * 100}%`,
    transition: "width 1s ease-in-out",
    background: color ? color : "#18fe17",
  } as React.CSSProperties;

  return (
    <div className={clsx("progress-container", className)}>
      <div className="progress-bar" style={{ background: bgColor ? bgColor : "#21283d" }}>
        <div className="progress-fill" style={progressFillStyle}></div>
      </div>
      <div className="progress-amount">
        {showBarStats && (
          <span className="amount">
            {minted} / {total} REMAINING
          </span>
        )}
      </div>
    </div>
  );
};

export default ProgressBarComponent;
