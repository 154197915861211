export enum BurnStatus {
  CONNECT_TO_BURN,
  QUALIFY_TO_BURN,
  NOT_QUALIFY_TO_BURN,
  READY_TO_BURN,
  REVIEW,
  FOLLOW,
  BURNING,
  BURN_SUCCEEDED,
  BURN_FAILED,
}

export enum ReadStatus {
  CONNECT_TO_READ,
  QUALIFY_TO_READ,
  NOT_QUALIFY_TO_READ,
  READING,
}

export enum ClaimStatus {
  CONNECT_TO_CLAIM,
  QUALIFY_TO_CLAIM,
  NOT_QUALIFY_TO_CLAIM, 
  READY_TO_CLAIM,
  CLAIMING,
  CLAIM_SUCCEEDED,
  CLAIM_FAILED,
}

export type Signature = {
  wallet: string;
  sig: string;
}

export interface TransactionResult {
  code: number;
  message: string;
  stack: string;
  hash: string;
}

export enum Edition {
  OS1_EDITION = 1,
  OS2_EDITION = 2,
  OS3_EDITION = 3,
}

export const OS_TOTAL_SUPPLY = 5902;

export const MAX_MINT_AMOUNT = 20;

export const ELITE_APE_TOKEN_ID = 0;
export interface IssuuComicConfig {
  title: string;
  issuuLink: string;
  slug: string;
}

export const TheComics = {
  comic1: {
    title: 'PUNKS Comic #1 : The Hunt for the Lost Robbies',
    issuuLink: 'https://e.issuu.com/embed.html?backgroundColor=%230C1121&backgroundColorFullscreen=%230c1121&d=punkscomic_issue1&hideIssuuLogo=true&hideShareButton=true&u=pixelvault',
    slug: 'punks-comic-1'
  },
  comic2: {
    title: 'PUNKS Comic #2 : X Marks the Drop',
    issuuLink: "https://e.issuu.com/embed.html?backgroundColor=%230C1121&backgroundColorFullscreen=%230c1121&d=punkscomic_issue2&hideIssuuLogo=true&hideShareButton=true&u=pixelvault",
    slug: 'punks-comic-2',
  },
  comic3: {
    title: 'PUNKS Comic #3 : A Glitch in Time',
    issuuLink: "https://e.issuu.com/embed.html?backgroundColor=%230c1121&backgroundColorFullscreen=%230c1121&d=punks_3_main&hideIssuuLogo=true&hideShareButton=true&u=pixelvault",
    slug: 'punks-comic-3',
  },
  apesOutOnTheTown: {
    title: 'Elite Ape Comic : Apes Out on the Town',
    issuuLink: "https://e.issuu.com/embed.html?backgroundColor=%230C1121&backgroundColorFullscreen=%230C1121&d=eliteapes_apesoutonthetown&hideIssuuLogo=true&hideShareButton=true&u=pixelvault",
    slug: 'apes-out-on-the-town'
  },
  os1: {
    title: "Origin Stories #1 : Mad Clown, Sad Town",
    issuuLink: "https://e.issuu.com/embed.html?backgroundColor=%230C1121&backgroundColorFullscreen=%230C1121&d=originstories_issue1_courtney&doAutoflipPages=true&hideIssuuLogo=true&hideShareButton=true&u=pixelvault",
    slug: 'origin-stories-1'
  },
  os2: {
    title: "Origin Stories #2: Eye for an I",
    issuuLink: "https://e.issuu.com/embed.html?backgroundColor=%230C1121&backgroundColorFullscreen=%230C1121&d=originstories_issue2_skull&doAutoflipPages=true&hideIssuuLogo=true&hideShareButton=true&u=pixelvault",
    slug: 'origin-stories-2'
  },
  os3: {
    title: "Origin Stories #3: The Trouble With Punks",
    issuuLink: "https://e.issuu.com/embed.html?backgroundColor=%230C1121&backgroundColorFullscreen=%230c1121&d=os3&hideIssuuLogo=true&hideShareButton=true&u=pixelvault",
    slug: 'origin-stories-3'
  },
  os4: {
    title: "Origin Stories #4: The Ultimate Degen",
    issuuLink: "https://e.issuu.com/embed.html?backgroundColor=%230C1121&backgroundColorFullscreen=%230c1121&d=os4_the_ultimate_degen&hideIssuuLogo=true&hideShareButton=true&u=pixelvault",
    slug: 'origin-stories-4'
  },
}

export const RaribleMarketplaceURL = {
  originStories: "https://market.pixelvault.com/section/punks/punks_origin"
}