import React from "react";
import "./App.css";
import ContractsData from "./utils/allContractData";
import AppRoutes from "./routes";
import { OSContractHelpers } from "./utils/OSContractHelpers";
import { MerchContractHelper } from "./utils/MerchContractHelper";

const App: React.FC = () => {
  const live = true;
  return (
    <div className="App">
      <ContractsData live={live} />
      <OSContractHelpers live={true} />
      <MerchContractHelper live={true} />
      <AppRoutes live={live} />
    </div>
  );
};

export default App;
