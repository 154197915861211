import FooterSection from "components/FooterSection/FooterSection";
import React, { ReactElement } from "react";
import styles from "./ForbiddenPage.module.scss";

const ForbiddenPage = (): ReactElement => {
  return (
    <>
      <main>
        <h1 className={styles.header}>403 - Forbidden</h1>
        <p>You don't have permission to access this site.</p>
      </main>
      <FooterSection />
    </>
  );
};

export default ForbiddenPage;
