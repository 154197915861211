import { ethers } from "ethers";
import useData from "../context/dataContext";
import { contractAddresses, networks } from "./networkConstants";
// import ComicABI from "./abi/comicABI.abi.json";
// import MPABI from "./abi/mpABI.abi.json";
// import Punks3ABI from "./abi/punk3ABI.abi.json";
// import Punks3SEABI from "./abi/punks3SEABI.abi.json";
// import eliteApeCoinAbi from "utils/abi/eliteApeCoinAbi.json";
// import eliteApeComicAbi from "utils/abi/eliteApeComicAbi.json";
import apeMadnessAbi from "blockchain/abi/apeMadnessAbi.json";
// import powABI from "blockchain/abi/powAbi.abi.json";

import { useEffect } from "react";
import { IS_MAINNET } from "../context/dataContext";

type networkProp = {
  live: boolean;
};

const ContractsData: React.FC<networkProp> = ({ live }) => {
  const {
    // setComic1Balance,
    // setComic3Balance,
    // setSpecialComic3Balance,
    // setHoloComic3Balance,
    // setMP1Balance,
    // setMetaheroGenBalance,
    // setMetaheroCoresBalance,
    // setFoundersDAOBalance,
    // setEliteApeCoinBalance,
    setIsMainnet,
    setNetwork,
    // setApeComicBalance,
    account,
    signer,
    // setApeComicContract,
    // setApeCoinContract,
    setApeMadnessContract,
  } = useData();

  const contracts = IS_MAINNET ? contractAddresses.mainnet : contractAddresses.rinkeby;
  const network = IS_MAINNET ? networks.mainnet : networks.rinkeby;

  useEffect(() => {
    setIsMainnet(IS_MAINNET);
    setNetwork(network);
    if (live) getContractsData();
    // eslint-disable-next-line
  }, [account]);

  // Get all contracts data
  const getContractsData = async () => {
    try {
      // const owner = "0xcf3bC13C0F19B9549364CC5F4b7EA807b737C062";

      // // Punks 1
      // const comic1Contract = getContract(contracts.comic1, ComicABI, provider);

      // const comic1Remaining = await getBalance(comic1Contract, owner);
      // setComic1Balance(130 - comic1Remaining);

      // // Punks 3
      // const comic3Contract = getContract(contracts.comic3, Punks3ABI, provider);

      // const comic3Supply = await comic3Contract.totalSupply();
      // const comic3Remaining = comic3Supply.toNumber();
      // setComic3Balance(comic3Remaining);

      // // Punks 3 Special Edition
      // const specialComic3Contract = getContract(contracts.specialComic3, Punks3SEABI, provider);

      // const specialComic3Supply = await specialComic3Contract.totalSupply();
      // const specialComic3Remaining = specialComic3Supply.toNumber();
      // setSpecialComic3Balance(specialComic3Remaining);

      // // Punks 3 Holo Edition
      // const holoComic3Contract = getContract(contracts.holoComic3, Punks3SEABI, provider);

      // const holoComic3Supply = await holoComic3Contract.totalSupply();
      // const holoComic3Remaining = holoComic3Supply.toNumber();
      // setHoloComic3Balance(holoComic3Remaining);

      // // Metahero Gen
      // const metaheroGenContract = getContract(contracts.metaheroGen, ComicABI, provider);

      // const metaheroGenRemaining = await getBalance(metaheroGenContract, owner);
      // setMetaheroGenBalance(500 - metaheroGenRemaining);

      // // Metahero Cores
      // const metaheroCoresContract = getContract(contracts.metaheroCores, ComicABI, provider);

      // const metaheroCoresRemaining = await getBalance(metaheroCoresContract, owner);
      // setMetaheroCoresBalance(12 - metaheroCoresRemaining);

      // // Founders DAO
      // const foundersContract = getContract(contracts.foundersDAO, ComicABI, provider);

      // const foundersDAORemaining = await getBalance(foundersContract, owner);
      // console.log(foundersDAORemaining);
      // setFoundersDAOBalance(665 - foundersDAORemaining);

      // // Mint Pass 1
      // const mp1Contract = getContract(contracts.mp1, MPABI, provider);

      // const mp1Remaining = await mp1Contract.balanceOf(owner, 0);
      // let num = mp1Remaining.toNumber();
      // setMP1Balance(350 - num);

      // Elite Ape
      // const apeCoincontract = new ethers.Contract(contracts.apeCoin, eliteApeCoinAbi, signer);
      // const apeComicContract = new ethers.Contract(contracts.apeComic, eliteApeComicAbi, signer);

      // setApeComicContract(apeComicContract);
      // const apeComicBalanceBigNum = await apeComicContract.balanceOf(account);
      // const apeComicAmount = parseInt(apeComicBalanceBigNum);
      // setApeComicBalance(apeComicAmount);
      // setApeCoinContract(apeCoincontract);
      // const eliteApeCoinBalanceBigNum = await apeCoincontract.balanceOf(account, ELITE_APE_TOKEN_ID);
      // const eliteApeCoinBalance = parseInt(eliteApeCoinBalanceBigNum);
      // setEliteApeCoinBalance(eliteApeCoinBalance);

      // Ape Madness
      const apeMadnessContract = new ethers.Contract(contracts.apeMadness, apeMadnessAbi, signer);
      setApeMadnessContract(apeMadnessContract);
    } catch (err: any) {
      console.error(err);
    }
  };

  return <></>;
};

export default ContractsData;
