import { useState } from "react";
import "./CounterComponent.css";

export enum COUNTER_ACTION_TYPE {
  BURN = "Burns",
  MINT = "Mints",
}

type CounterComponentProps = {
  actionType: COUNTER_ACTION_TYPE;
  maxAmount: number;
  onSelect: (amount: number) => void;
};

const CounterComponent: React.FC<CounterComponentProps> = props => {
  const { actionType, maxAmount, onSelect } = props;
  const [counter, setCounter] = useState<number>(maxAmount);
  let text = false;

  let incrementCount = () => setCounter(counter + 1);
  let decrementCount = () => setCounter(counter - 1);

 if (counter >= maxAmount) {
    // incrementCount = () => setCounter(maxAmount);
    text = true;
  }

  return (
    <>
      <div className="counter-container">
        <button
          onClick={() => {
            if(counter <= 1) {
              setCounter(1);
            } else {
              decrementCount();
              onSelect(counter - 1);
            }
          }}
          className="counter-btn"
        >
          -
        </button>
        <div className="amount-count">{counter}</div>
        <button
          onClick={() => {
            if(counter >= maxAmount) {
              setCounter(maxAmount);
              text = true;
            } else {
              incrementCount();
              onSelect(counter + 1);
            }
          }}
          className="counter-btn"
        >
          +
        </button>
      </div>
      <p className="text-container">{text ? `Maximum ${actionType} Reached` : ""}</p>
    </>
  );
};

export default CounterComponent;
