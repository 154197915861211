import clsx from "clsx";
import React, { ReactElement, ReactNode } from "react";

import styles from "./Card.module.scss";

type Props = {
  children?: ReactNode;
  backgroundImage?: string;
  className: string;
};

const Card = ({ children, backgroundImage: bkgImg, className: classNameProp }: Props): ReactElement => {
  return (
    <div
      className={clsx(classNameProp, styles.cardContainer)}
      style={{
        backgroundImage: bkgImg ? `url(${bkgImg})` : "",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      {children}
    </div>
  );
};

export default Card;
