import * as React from "react";
import Box from "@mui/material/Box";
import { ReactNode } from "react";
import clsx from "clsx";

import styles from "./BoxComponent.module.scss";

type Props = {
  children: ReactNode;
  color?: string;
  className?: string;
};

const BoxComponent = ({ children, color = "#14C918", className: classNameProp }: Props) => {
  return (
    <Box
      component="span"
      sx={{ p: 4, border: `1px solid ${color}`, borderRadius: 2 }}
      className={clsx(styles.boxContainer, classNameProp)}
    >
      {children}
    </Box>
  );
};

export default BoxComponent;
