import { ReactElement, useContext, useEffect } from "react";
import { ethers } from "ethers";
import { IS_MAINNET } from "context/dataContext";
import useData from "context/dataContext";
import { MerchContext } from "context/merchContext";
import { contractAddresses, networks } from "./networkConstants";
import powABI from "blockchain/abi/powAbi.abi.json";
import merchABI from "blockchain/abi/merchABI.abi.json";

type Props = {
  live: boolean;
};

export const MerchContractHelper = ({ live }: Props): ReactElement => {
  const { account, signer, setNetwork } = useData();

  const {
    setPowBalance,
    setPunksBalance,
    setPowContract,
    setPunksContract,
    setMerchContract,
    setMerchItemsAmount,
    setItemsInfo,
    punksBalance,
    powBalance,
  } = useContext(MerchContext);

  const contracts = IS_MAINNET ? contractAddresses.mainnet : contractAddresses.goerli;
  const network = IS_MAINNET ? networks.mainnet : networks.goerli;

  useEffect(() => {
    setNetwork(network);
    if (live) initializeMerchContractData();
    // eslint-disable-next-line
  }, [account, powBalance, punksBalance]);

  const initializeMerchContractData = async () => {
    try {
      /** Contracts */
      const powContract = new ethers.Contract(contracts.pow, powABI, signer);
      setPowContract(powContract);

      const punksContract = new ethers.Contract(contracts.punks, powABI, signer);
      setPunksContract(punksContract);

      const merchContract = new ethers.Contract(contracts.merch, merchABI, signer);
      setMerchContract(merchContract);

      /** Set Balances */
      const powCurrBalance = await powContract.balanceOf(account);
      const powNum = Number(ethers.utils.formatEther(powCurrBalance)).toFixed(2);
      setPowBalance(Number(powNum));

      const punksAmount = await punksContract.balanceOf(account);
      const punksBal = Number(ethers.utils.formatEther(punksAmount)).toFixed(2);
      setPunksBalance(Number(punksBal));

      /** Merch Items */
      const merchAmount = await merchContract.id();
      const merchAmountValue = Number(merchAmount);
      setMerchItemsAmount(merchAmountValue);

      let itemsArr = [];
      for (let i = 0; i < merchAmountValue; i++) {
        const amountOfSizes = await merchContract.sizesOf(i);
        const amountOfSizesNum = Number(amountOfSizes);

        // if claimed
        const isItemClaimed = (await merchContract.addrIDSize(account, i)) !== 0;
        // const isItemClaimed = await merchContract.addrClaimedID(account, i);

        // for pow
        const costOfItemPow = await merchContract.costOf(i, true);
        const costOfItemPowNum = Number(ethers.utils.formatEther(costOfItemPow)).toFixed(0);

        // for punks
        const costOfItemPunks = await merchContract.costOf(i, false);
        const costOfItemPunksNum = Number(ethers.utils.formatEther(costOfItemPunks)).toFixed(0);

        let maxOfSizesNum = [];
        let claimedOfSizesNum = [];

        for (let j = amountOfSizesNum - (amountOfSizes - 1); j <= amountOfSizesNum; j++) {
          const maxOfSize = await merchContract.maxOfIDSize(i, j - 1);
          maxOfSizesNum.push(Number(maxOfSize));

          const claimedOfSize = await merchContract.claimedOfIDSize(i, j - 1);
          claimedOfSizesNum.push(Number(claimedOfSize));
        }

        let itemObj = {
          amount_of_sizes: amountOfSizesNum,
          max_of_sizes: maxOfSizesNum,
          claimed_of_sizes: claimedOfSizesNum,
          cost_of_in_pow: Number(costOfItemPowNum),
          cost_of_in_punks: Number(costOfItemPunksNum),
          is_claimed: isItemClaimed,
        };
        itemsArr.push(itemObj);
      }

      setItemsInfo(itemsArr);
    } catch (err: any) {
      console.log(err);
    }
  };
  return <></>;
};

export default MerchContractHelper;
