import { useState, createContext, ReactElement, useEffect } from "react";
import { Contract } from "ethers";
import useData from "context/dataContext";

export const MerchContext = createContext<any | null>(null);

type Props = {
  children: ReactElement;
};

export const MerchProvider = ({ children }: Props) => {
  const { account } = useData();
  /** contracts */
  const [powContract, setPowContract] = useState<Contract>();
  const [punksContract, setPunksContract] = useState<Contract>();
  const [merchContract, setMerchContract] = useState<Contract>();

  /** balances */
  const [powBalance, setPowBalance] = useState<number>(0);
  const [punksBalance, setPunksBalance] = useState<number>(0);

  /** merch cart */
  const [merchItemsAmount, setMerchItemsAmount] = useState<number>(0);
  const [cartAmount, setCartAmount] = useState<number>(0);
  const [cartItems, setCartItems] = useState<[]>([]);
  const [itemsInfo, setItemsInfo] = useState<[]>([]);

  /** shipping not paid orders */
  const [missingShippingOrders, setMissingShippingOrders] = useState<[]>([]);
  const [missingShippingOrdersDets, setMissingShippingOrdersDets] = useState<[]>([]);

  useEffect(() => {
    if (account) {
      const data = window.localStorage.getItem("Cart_Items");
      if (data !== null) {
        setCartItems(JSON.parse(data));
        setCartAmount(JSON.parse(data).length);
      }
    }
  }, [account]);

  return (
    <MerchContext.Provider
      value={{
        powBalance,
        setPowBalance,
        punksBalance,
        setPunksBalance,
        powContract,
        setPowContract,
        punksContract,
        setPunksContract,
        cartAmount,
        setCartAmount,
        cartItems,
        setCartItems,
        merchContract,
        setMerchContract,
        merchItemsAmount,
        setMerchItemsAmount,
        itemsInfo,
        setItemsInfo,
        missingShippingOrders,
        setMissingShippingOrders,
        missingShippingOrdersDets,
        setMissingShippingOrdersDets,
      }}
    >
      {children}
    </MerchContext.Provider>
  );
};
