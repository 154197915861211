import clsx from "clsx";
import React, { ReactElement, ReactNode } from "react";

import styles from "./ImageCard.module.scss";

type Props = {
  image: string;
  title: string | ReactElement | ReactElement[];
  description: string;
  children?: ReactNode;
  className?: string;
};

const ImageCard = ({ image, title, description, children, className: classNameProp }: Props): ReactElement => {
  return (
    <div className={clsx(classNameProp, styles.imageCardContainer)}>
      <div className={styles.cardImage}>{<img src={image} className={styles.image} alt="" loading="lazy" />}</div>
      <div className={styles.cardInfo}>
        {typeof title === "string" ? (
          <h5 className={styles.cardTitle} dangerouslySetInnerHTML={{ __html: title }} />
        ) : (
          <h5 className={styles.cardTitle}>{title}</h5>
        )}
        {/* <h5 className={styles.cardTitle}>{title}</h5> */}
        <p className={styles.cardDescription}>{description}</p>
      </div>
      <div className={styles.childrenContainer}>{children}</div>
    </div>
  );
};

export default ImageCard;
