import "./SectionComponent.css";
import ButtonComponent from "../ButtonComponent/ButtonComponent";
import Punks3Cover from "../../images/desktop/punks3_cover.jpg";
import Punks3Orb from "../../images/desktop/punks3_orb@2x.png";
import safari from "../../images/pixel-animation-hevc-safari.mp4";
import chrome from "../../images/pixel-animation-vp9-chrome.webm";

type SectionComponentProps = {
  hasButton?: boolean;
  sectionName: string;
};

const SectionComponent: React.FC<SectionComponentProps> = props => {
  const { hasButton, sectionName } = props;

  const sectionQuery: any = {
    glitch: {
      title: "A Glitch In Time",
      topSubTitle: "Punks Comic: ISSUE #3",
      bottomSubTitle: "Proceed with caution...",
      text: "Just as it seemed life couldn’t get any crazier, the PUNKS find themselves thrust through strange portals into another world full of super-powered humans, including augmented versions of familiar faces! If gfunk, Courtney and crew are to make it back to Origin City alive, they may just have to find a way to take on super, augmented attributes themselves, and risk chancing that these “Glitch Portals” will return them to the right location.",
      imgSrc: Punks3Cover,
      className: "glitch",
    },
    orb: {
      title: "We've Come A Long Way",
      topSubTitle: null,
      bottomSubTitle: null,
      text: "Thanks to Frank and his interdimensional time travel device, we find ourselves in a tricky position. Minting PUNKS 3 will require opening the portal, and portal travel technology is finicky to say the least. You never know what may happen when entering a glitchy portal.",
      imgSrc: Punks3Orb,
      className: "orb",
    },
    cores: {
      title: "INTERDIMENSIONAL TRAVELERS BEWARE",
      topSubTitle: null,
      bottomSubTitle: "portal unlocks 07.01.22",
      text: " A fragile portal may return a PUNKS Comic #3 as intended, or one of the following at random: a Generative MetaHero Identity, Core MetaHero Identity, PUNKS Comic #1, Pixel Vault Founder’s DAO, MintPass #1, or even a rarer version of PUNKS Comic #3.",
      className: "cores",
      id: "enter-portal",
    },
  };

  return (
    <>
      <section
        id={sectionQuery[sectionName].id ? sectionQuery[sectionName].id : null}
        className={`${sectionQuery[sectionName].className}-section`}
      >
        <div className={`${sectionQuery[sectionName].className}-pixel`}></div>
        <div className="container">
          <div className="info-container">
            <p className="top-subtitle">{sectionQuery[sectionName].topSubTitle}</p>
            <h1 className="info-title">{sectionQuery[sectionName].title}</h1>
            <p className="info-text">{sectionQuery[sectionName].text}</p>
            {hasButton && sectionQuery[sectionName].className === "cores" ? (
              <ButtonComponent link="https://punkscomic.com/mint-punks3" label="UNLOCK MINTING PORTAL" />
            ) : sectionQuery[sectionName].className === "glitch" ? (
              <>
                <ButtonComponent
                  label={hasButton ? "MINT PUNKS 3" : "READ THE COMIC"}
                  link={hasButton ? "#enter-portal" : "#pdf"}
                />
                <p className="bottom-subtitle">{sectionQuery[sectionName].bottomSubTitle}</p>
              </>
            ) : sectionQuery[sectionName].className === "cores" ? (
              <>
                <ButtonComponent
                  link="https://medium.com/@punkscomic/punks-comic-issue-3-what-you-need-to-know-faq-a08ac5e51157"
                  label="LEARN MORE"
                  target="_blank"
                />
                <p className="bottom-subtitle">{sectionQuery[sectionName].bottomSubTitle}</p>
              </>
            ) : null}
          </div>
          <div className="image-container">
            {sectionQuery[sectionName].imgSrc ? (
              <img
                className={`${sectionQuery[sectionName].className}-img`}
                src={sectionQuery[sectionName].imgSrc}
                alt="relating to text"
              />
            ) : (
              <video className="animation-gif" autoPlay loop muted playsInline>
                <source src={safari} type='video/mp4; codecs="hvc1"' />
                <source src={chrome} type="video/webm" />
              </video>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default SectionComponent;
