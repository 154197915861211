import { ethers, Contract } from "ethers";
import { networks } from "utils/networkConstants";
import { IS_MAINNET } from "context/dataContext";

export const isWalletExtensionExists = () => {
  return window.ethereum !== undefined;
}

export const connectWalletHandler = async () => {
  const { ethereum } = window;

  if (!ethereum) alert('please install metamask');

  try {
    const accounts = await  ethereum.request({ method: 'eth_requestAccounts' });
  } catch (error) {
    console.log(error);
  }
}

// get the infura provider for basic 'reads' of network data
export const infuraProvider: ethers.providers.InfuraProvider = IS_MAINNET ? 
new ethers.providers.InfuraProvider(networks.mainnet.name, networks.mainnet.apiKey) 
: new ethers.providers.InfuraProvider(networks.goerli.name, networks.goerli.apiKey);

// Get access to the contract by connecting to the contract using a provider
export const getContract = (contractAddress: string, abi: Object[], provider: ethers.providers.InfuraProvider): Contract => new ethers.Contract(contractAddress, abi, provider);

export const getBalance = async (contract: Contract, account: string): Promise<number> => {
  const balance = await contract.balanceOf(account);
  return balance.toLocaleString('en-US');
};

export const burn = async (contract: Contract, account: string, tokenId: number, value: number) => {
  let state = null;
  let STATE = null;

  let balance = await getBalance(contract, account);
  if (balance < 1) return null;
  
  try {
    STATE = await contract.burn(account, tokenId, value);
    state = await STATE.wait();
  } catch (err) {
    console.log('burn has failed', err);
  }
  return state;
}

export const mint = async (contract: Contract, amount: number) => {
  let state = null;
  let STATE = null;

  try {
    STATE = await contract.mint(amount);
    state = await STATE.wait();
  } catch (err) {
    console.log('mint has failed', err);
  }
  return state;
};

export const claim = async () => {
  return null;
}

/**
 * @param ownerAddress the account address of the owner
 * @param operatorContract the contract address of the operator
 * @param contract the actual contract which we want to read from
 *
 * Return whether the owner has approval for the operator (a contract which can operates)
 */
export  const ownerHasApprovalOnOperator = async (
  ownerAddress: any,
  operatorContract: any,
  contract: Contract
): Promise<boolean> => {
  let isApproved = await contract.isApprovedForAll(ownerAddress, operatorContract);
  return isApproved;
};