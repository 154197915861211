import React, { ReactElement } from "react";
import clsx from "clsx";

import "./Section.css";

type Props = {
	children: ReactElement;
	className?: string;
	id?: string;
};

const Section = ({
	children,
	className: classNameProp,
	id: idProp,
	...props
}: Props): ReactElement => {
	const className = clsx("split-pane", classNameProp);

	return (
		<section {...props} id={idProp} className={className}>
			{children}
		</section>
	);
};

export default Section;
