import clsx from "clsx";
import React, { ReactElement, ReactNode } from "react";
import { Modal as SemanticModal, ModalProps } from "semantic-ui-react";

import styles from "./ConfirmationModal.module.scss";

export enum ACTION_STATUS {
  DEFAULT = "default",
  SUCCESS = "success",
  WARNING = "warning",
}

type Props = ModalProps & {
  title: string;
  message: string | ReactElement | ReactElement[];
  children?: ReactNode;
  isOpen: boolean;
  hasButton?: boolean;
  btnText?: string;
  btnStyle?: "default" | "transparent" | "filled";
  buttonDisabled?: boolean;
  caption?: string | ReactElement | ReactElement[];
  setOpen: (value: boolean) => void;
  onConfirm: () => any;
  className?: string;
  hasAltButton?: boolean;
  altBtnText?: string;
  altIsDisabled?: boolean;
  handleAltClick?: () => any;
  modalTheme: ACTION_STATUS.DEFAULT | ACTION_STATUS.SUCCESS | ACTION_STATUS.WARNING;
};

const ConfirmationModal = ({
  title,
  message,
  isOpen,
  setOpen,
  children,
  onConfirm,
  hasButton = true,
  btnText = "",
  btnStyle: btnVariant = "default",
  buttonDisabled = false,
  hasAltButton,
  handleAltClick,
  altIsDisabled,
  altBtnText,
  caption,
  className: classNameProp,
  modalTheme: modalType = ACTION_STATUS.DEFAULT,
}: Props): ReactElement => {
  const titleClassName = clsx([
    styles.title,
    modalType === ACTION_STATUS.DEFAULT && "default",
    modalType === ACTION_STATUS.WARNING && "warning",
    modalType === ACTION_STATUS.SUCCESS && "success",
  ]);

  const buttonClassName = clsx([
    "btn",
    btnVariant === "transparent" && "btn-primary-transparent",
    btnVariant === "filled" && "btn-primary-green",
    btnVariant === "default" && "btn-primary-green",
    styles.confirmButton,
  ]);

  const handleConfirm = (): void => {
    try {
      onConfirm();
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <SemanticModal
      className={clsx(styles.confirmationModal, classNameProp)}
      open={isOpen}
      setIsOpen={setOpen}
      size="mini"
    >
      <SemanticModal.Content>
        <div className={clsx(styles.container)}>
          <button
            className={styles.closeButton}
            onClick={(): void => {
              setOpen(false);
            }}
          ></button>

          <div className={styles.main}>
            <h3 className={titleClassName}>{title}</h3>
            {typeof message === "string" ? (
              <div className={styles.message} dangerouslySetInnerHTML={{ __html: message }} />
            ) : (
              <div className={styles.message}>{message}</div>
            )}

            {children && children}
            <div className={styles.buttonContainer}>
              {hasButton && (
                <button onClick={handleConfirm} className={buttonClassName} disabled={buttonDisabled}>
                  {btnText}
                </button>
              )}
              {hasAltButton && (
                <button onClick={handleAltClick ? () => handleAltClick() : () => {}} className="btn btn-secondary h3rem" disabled={altIsDisabled}>
                  {altBtnText}
                </button>
              )}
            </div>
            {caption && <p className={styles.caption}>{caption}</p>}
          </div>
        </div>
      </SemanticModal.Content>
    </SemanticModal>
  );
};

export default ConfirmationModal;
