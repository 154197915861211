import axios from "axios";
require("dotenv").config();

// WILL NEED TO EDIT THIS ALSO WILL NEED NEW KEY FOR BE
const baseURL = process.env.REACT_APP_BACKEND_BASE_URL || "https://unique-toothpaste-dev.up.railway.app";

let headers = {
  "Content-Type": "application/json",
};

const axiosInstance = axios.create({
  baseURL: baseURL,
  headers,
  timeout: 10000,
  responseType: "json",
});

export const axiosInstancePunksComic = axios.create({
  baseURL: "https://punkscomic.com",
  headers: { "Content-Type": "application/json" },
  timeout: 10000,
  responseType: "json",
});

export default axiosInstance;
