export enum CHAIN_IDS {
  ETHEREUM = 1,
  RINKEBY = 4,
  GOERLI = 5,
}

export const etherscanUrl = {
  rinkeby: "https://rinkeby.etherscan.io/tx",
  mainnet: "https://etherscan.io/tx",
  goerli: "https://goerli.etherscan.io/tx",
};

const networks = {
  mainnet: {
    chainId: CHAIN_IDS.ETHEREUM,
    apiKey: "493d2ac9a0e34c5dadd3272dfffd432a",
    etherscanApiKey: "JWA5EPQIPCH5H4YARZYSVMG7A2EK53R9WB",
    nodeURL: "https://mainnet.infura.io/v3/9efc8827d1694b21bbf01cb03097b2d1",
    name: "mainnet",
  },
  rinkeby: {
    chainId: CHAIN_IDS.RINKEBY,
    apiKey: "493d2ac9a0e34c5dadd3272dfffd432a",
    etherscanApiKey: "JWA5EPQIPCH5H4YARZYSVMG7A2EK53R9WB",
    nodeUrl: "https://rinkeby.infura.io/v3/9efc8827d1694b21bbf01cb03097b2d1",
    name: "rinkeby",
  },
  goerli: {
    chainId: CHAIN_IDS.GOERLI,
    apiKey: "493d2ac9a0e34c5dadd3272dfffd432a",
    etherscanApiKey: "JWA5EPQIPCH5H4YARZYSVMG7A2EK53R9WB",
    nodeUrl: "https://goerli.infura.io/v3/9efc8827d1694b21bbf01cb03097b2d1",
    name: "goerli",
  },
};

const contractAddresses = {
  mainnet: {
    comic1: "0x5ab21Ec0bfa0B29545230395e3Adaca7d552C948",
    comic2: "",
    comic3: "0xB716600Ed99B4710152582a124C697A7Fe78ADBF",
    apeCoin: "0xd0B53410454370a482979C0adaf3667c6308a801",
    apeComic: "0x6cBE9EDEC134b9d750F4f515dC02A9Bd656ed512",
    apeMadness: "0x741F319D2d0ee184C609B7A8d1D245acf7810F7F",
    specialComic3: "0x0BebAD1fF25c623DFF9605daD4a8F782d5DA37dF",
    holoComic3: "0x66d1db16101502eD0CA428842c619ca7B62C8fEf",
    mp1: "0x797a48c46be32aafcedcfd3d8992493d8a1f256b",
    metaheroGen: "0x6dc6001535e15b9def7b0f6A20a2111dFA9454E2",
    metaheroCores: "0xfb10b1717c92e9cc2d634080c3c337808408d9e1",
    foundersDAO: "0xd0A07a76746707f6D6d36D9d5897B14a8e9ED493",
    os1: "0x54439D4908A3E19356F876aa6022D67d0b3B12d6",
    os2: "0x987560855817480e27fb9e5C22B170565404DF40",
    os3: "0x92875b07e77a8ac130269ef12f1d682fbc9049ac",
    os4: "0xfe13e643F8A710Fd8B411FcF1E30878E1c0C2354",
    pow: "0x43Ab765ee05075d78AD8aa79dcb1978CA3079258",
    punks: "0x30c084890fc07d78f3499ffc818b3225bc8812ea",
    merch: "",
  },
  rinkeby: {
    comic1: "0x451e8b11760ee54d25cB18cAeE8b3Dd30479362d",
    comic2: "0x2E2d6152cC4b9Aa6d00A1708C2D8BdD40673cad1",
    comic3: "0x7CCC89F34402Ab639B46f2043af4b32dd00E01Ab",
    apeCoin: "0x11EBC8D089ea33F6eEdf48D2AA418Ae7712bD4bE",
    apeComic: "0x4e6a3e29C141E688EEb773820D23ad13909f979e",
    apeMadness: "0xF93D4837Cec4ca2798d691d4c31bc300c8a872E1",
    specialComic3: "0x5F3DE0d58AeA10395CCaDccC67209B76b59BF480",
    holoComic3: "0x37b001063f2A3AA5eA13Dc790a1c89609F337Fe9",
    mp1: "0x2d58E280d8A8aBa6Dc5E943d0b3511a9d0D9430E",
    metaheroGen: "0xFFE87c0C34e9B1B8885e08Bc386637B47cD09290",
    metaheroCores: "0x09Ea144a06fb0032b03a16ADEEA3659A9036EF45",
    foundersDAO: "0x97C300456c81f02A77402449c9Ebc523B3700c6f",
    os1: "0x7EDf09581cA180c8555359198fa32E4a82Fcbb2a",
    os2: "0xEde50ae9FfEA50525aC8B85B7275404EbF572c0B",
    os3: "0xE20FcA4E4060DBaE9685875a5a1c590aa2C0f160",
    os4: "0x1A2DE24574c2C21F14A027363Ced9F254ac542ad",
    pow: "0xa58a8d12a23597D7b8371bA33aB495B0b50e3a91",
    punks: "0xAE3e05583270c677819e77B82Ec93aB54AB8e01D",
    merch: "0x4f0c1F2273Da0F2480d7cd7a8DD989C384Fa67bF",
  },
  goerli: {
    os1: "0x71C427B2EF2B42885193cB40bF57Fe8ae0181865",
    os2: "0xc00EEdc68395cbd0940FF82E7d6f97BE1f69b67F",
    os3: "0x31d343b8d3a4fbcc2cdcc2f0bd48c257f2d93547",
    os4: "0xFA4B645Fef208321343EfF3ccc75C8FB35569078",
    pow: "0xae3e05583270c677819e77b82ec93ab54ab8e01d",
    punks: "0xa58a8d12a23597d7b8371ba33ab495b0b50e3a91",
    merch: "0xa14374e0626fd7700de27f28dd354be62451e1e8",
  },
};

export const OPENSEA_BASE_URL = "https://opensea.io/collection";

export { networks, contractAddresses };
