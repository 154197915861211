import React from "react";
import clsx from "clsx";

import "styles/base.css";
import styles from "./ContentDescription.module.scss";
import ButtonComponent from "components/ButtonComponent/ButtonComponent";

export type ContentDescriptionProps = {
  title: string;
  subtitle?: string;
  content: string;
  buttonText?: string;
  buttonClassName?: string;
  isConnectBtn?: boolean;
  onClick?: () => void;
  captionText?: string;
  className?: string;
};

const ContentDescription = ({
  title,
  subtitle,
  content,
  buttonText,
  buttonClassName,
  isConnectBtn,
  onClick,
  captionText,
  className: classNameProp,
  ...props
}: ContentDescriptionProps) => {
  const className = clsx(styles.contentDescriptionContainer, classNameProp);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    e.stopPropagation();

    if (onClick) {
      onClick && onClick();
    }
  };

  return (
    <div className={className} {...props}>
      <h2 className={styles.contentTitle}>{title}</h2>
      <p className={styles.contentDescription}>{content}</p>
      {isConnectBtn && <ButtonComponent isConnectButton={true} label={buttonText ? buttonText : ""} />}

      {!isConnectBtn && buttonText && (
        <div className={styles.buttonContainer}>
          <button onClick={handleClick} className={clsx(`btn`, buttonClassName)}>
            {buttonText}
          </button>
        </div>
      )}
      <p className={styles.captionText}>{captionText}</p>
    </div>
  );
};

export default ContentDescription;
