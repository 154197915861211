import "../PhaseSection/PhaseComponent.css";
import PhaseComponent from "./PhaseComponent";
import Section from "../Section/Section";
import Column from "../Section/Column/Column";
import Punks3Img from "../../images/punks3.0.jpg";
import Punks1Img from "../../images/punks1.jpg";
import Punks3SpecialImg from "../../images/punks3.1.jpg";
import Punks3HoloImg from "../../images/punks3.2.jpg";
import daoImg from "../../images/founders_dao.png";
import mpImg from "../../images/mintpass1.png";
import metaheroGenImg from "../../images/gen-sm.gif";
import metaheroCoresImg from "../../images/cores-sm.gif";
import useData from "../../context/dataContext";

type ifLiveProp = {
  live: boolean;
};

const PhaseSectionComponent: React.FC<ifLiveProp> = ({ live }) => {
  const {
    comic1Balance,
    comic3Balance,
    specialComic3Balance,
    holoComic3Balance,
    mp1Balance,
    metaheroGenBalance,
    metaheroCoresBalance,
    foundersDAOBalance,
  } = useData();

  const poolQuery1 = [
    {
      title: "Punks 3: Main Edition",
      claimed: `${comic3Balance} / 14,693 Claimed`,
      quantity: "14,693 / 14,693 Claimed",
      imgSrc: Punks3Img,
    },
    {
      title: "Punks 3: Collector's Edition",
      claimed: `${specialComic3Balance} / 1,500 Claimed`,
      quantity: "1,500 / 1,500 Claimed",
      imgSrc: Punks3SpecialImg,
    },
    {
      title: "Punks 3: Holo/Signed",
      claimed: `${holoComic3Balance} / 150 Claimed`,
      quantity: "150 / 150 Claimed",
      imgSrc: Punks3HoloImg,
    },
    {
      title: "Punks 1: Main Edition",
      claimed: `${comic1Balance} / 130 Claimed`,
      quantity: "130 / 130 Claimed",
      imgSrc: Punks1Img,
    },
    {
      title: "Founder's DAO",
      claimed: `${foundersDAOBalance} / 665 Claimed`,
      quantity: "665 / 665 Claimed",
      imgSrc: daoImg,
    },
    {
      title: "MintPass 1",
      claimed: `${mp1Balance} / 350 Claimed`,
      quantity: "350 / 350 Claimed",
      imgSrc: mpImg,
    },
    {
      title: "Generative Metahero",
      claimed: `${metaheroGenBalance} / 500 Claimed`,
      quantity: "500 / 500 Claimed",
      imgSrc: metaheroGenImg,
    },
    {
      title: "Core Metahero",
      claimed: `${metaheroCoresBalance} / 12 Claimed`,
      quantity: "12 / 12 Claimed",
      imgSrc: metaheroCoresImg,
    },
  ];

  return (
    <>
      <Section className="phase-section minting">
        <>
          <Column>
            <div className="pool">
              <div className="content-title">What May Come Through The Portal</div>
              <div className="pool-grid">
                {poolQuery1.map(item => (
                  <div className="flex-row">
                    <img src={item.imgSrc} alt="" />
                    <div className="flex-column">
                      <div className="pool-title">{item.title}</div>
                      <div className="pool-claimed">
                        {/* {live ? item.claimed : item.quantity} */}
                        {item.quantity}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Column>
        </>
      </Section>
      <Section className="phase-section">
        <>
          <Column>
            <PhaseComponent />
          </Column>
        </>
      </Section>
    </>
  );
};

export default PhaseSectionComponent;
