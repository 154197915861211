import React, { ReactElement, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import clsx from "clsx";
import { Contract } from "ethers";
import useData, { IS_MAINNET } from "context/dataContext";
import { OSContext } from "context/osContext";
import { BurnStatus, MAX_MINT_AMOUNT, Edition, TransactionResult, RaribleMarketplaceURL } from "../consts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Circles } from "components/Loaders/circles";
import { etherscanUrl, contractAddresses } from "utils/networkConstants";
import { getIndicesArrayFromNum } from "utils/utils";
import { ownerHasApprovalOnOperator } from "blockchain/contractHandlers";
import Column from "components/Section/Column/Column";
import Card from "components/Card/Card";
import FireImg from "images/fire.svg";
import OS2ComicCover from "images/os2ComicCover.webp";
import OS1ComicCover from "images/os1Cover.webp";
import OS3NFTCover from "images/os3NFTCover.webp";
import OS3Logo from "images/os3Logo.svg";
import BoxComponent from "components/BoxComponent/BoxComponent";
import ContentDescription from "components/ContentDescription/ContentDescription";
import ButtonComponent from "components/ButtonComponent/ButtonComponent";
import FooterSection from "components/FooterSection/FooterSection";
import ImageCard from "components/ImageCard/ImageCard";
import ProgressBarComponent from "components/ProgressBarComponent/ProgressBarComponent";
import CheckboxComponent from "components/Checkbox/Checkbox";
import ConfirmationModal, { ACTION_STATUS } from "components/ConfirmationModal/ConfirmationModal";
import CheckboxStyles from "components/Checkbox/Checkbox.module.scss";

import styles from "./OS3Page.module.scss";

const eventClosed = true;

const OS3ContractAddress = IS_MAINNET ? contractAddresses.mainnet.os3 : contractAddresses.goerli.os3;

const OS3Page = (): ReactElement => {
  const navigate = useNavigate();

  const { account } = useData();

  const {
    os1ComicBalance,
    os1ComicContract,
    os2ComicBalance,
    os2ComicContract,
    os3ComicContract,
    setOS1ComicBalance,
    setOS2ComicBalance,
    os1ComicTotalSupply,
    os2ComicTotalSupply,
    os3ComicTotalSupply,
  } = useContext(OSContext);

  const [mintStatus, setMintStatus] = useState<BurnStatus>(BurnStatus.CONNECT_TO_BURN);
  const [mintModalOpen, setMintModalOpen] = useState(false);
  const [os1AmountToBurn, setOs1AmountToBurn] = useState<number>(1);
  const [os2AmountToBurn, setOs2AmountToBurn] = useState<number>(1);
  const [mintTxnState, setMintTxnState] = useState<TransactionResult>();
  const [mintError, setMintError] = useState<any>("");

  const [os1TokenIds, setOS1TokenIds] = useState<number[]>([]);
  const [os2TokenIds, setOS2TokenIds] = useState<number[]>([]);

  const [selectedOS1MultiTokenIds, setSelectedOS1MultiTokenIds] = useState<number[]>([]);
  const [selectedOS2MultiTokenIds, setSelectedOS2MultiTokenIds] = useState<number[]>([]);

  const [os1ItemsChecked, setIsOS1ItemsChecked] = useState<number[]>([]);
  const [os2ItemsChecked, setIsOS2ItemsChecked] = useState<number[]>([]);

  const [hasBalance, setHasBalance] = useState(os1ComicBalance + os2ComicBalance >= 1 ? true : false);
  const [isLoadingTokenIds, setIsLoadingTokenIds] = useState(false);

  useEffect(() => {
    if (account && mintStatus !== BurnStatus.BURN_SUCCEEDED) {
      let userHasBalance = os1ComicBalance > 0 || os2ComicBalance > 0 ? true : false;
      setHasBalance(userHasBalance);
      userHasBalance ? displayQualifyToBurnModal() : displayNotQualifyToBurnModal();
    }
    // eslint-disable-next-line
  }, [os1ComicBalance, os2ComicBalance]);

  const openMintModal = (): void => setMintModalOpen(true);
  const closeMintModal = (): void => setMintModalOpen(false);
  const displayConnectModal = () => {
    openMintModal();
    setMintStatus(BurnStatus.CONNECT_TO_BURN);
  };
  const displayQualifyToBurnModal = () => {
    openMintModal();
    setMintStatus(BurnStatus.QUALIFY_TO_BURN);
  };

  const displayNotQualifyToBurnModal = () => {
    openMintModal();
    setMintStatus(BurnStatus.NOT_QUALIFY_TO_BURN);
  };
  const displayReadyToBurnModal = () => setMintStatus(BurnStatus.READY_TO_BURN);
  const displayReviewModal = () => setMintStatus(BurnStatus.REVIEW);
  const displayBurnFailedModal = () => setMintStatus(BurnStatus.BURN_FAILED);
  const displayBurnSucceededModal = () => setMintStatus(BurnStatus.BURN_SUCCEEDED);
  const displayFollowWalletInstructionsModal = () => setMintStatus(BurnStatus.FOLLOW);

  const handleOS1SelectAll = (e: any) => {
    const { checked } = e.target;
    if (checked) {
      setIsOS1ItemsChecked(getIndicesArrayFromNum(os1TokenIds.length));
      setSelectedOS1MultiTokenIds(os1TokenIds);
    } else {
      setIsOS1ItemsChecked([]);
      setSelectedOS1MultiTokenIds([]);
    }
  };

  const handleOS2SelectAll = (e: any) => {
    const { checked } = e.target;
    if (checked) {
      setIsOS2ItemsChecked(getIndicesArrayFromNum(os2TokenIds.length));
      setSelectedOS2MultiTokenIds(os2TokenIds);
    } else {
      setIsOS2ItemsChecked([]);
      setSelectedOS2MultiTokenIds([]);
    }
  };

  const handleOS1SelectItem = (e: any, index: number) => {
    const { checked } = e.target;

    if (checked) {
      setIsOS1ItemsChecked([...os1ItemsChecked, index]);
      setSelectedOS1MultiTokenIds([...selectedOS1MultiTokenIds, os1TokenIds[index]]);
    } else {
      setIsOS1ItemsChecked(os1ItemsChecked.filter(item => item.valueOf() !== index));
      setSelectedOS1MultiTokenIds(selectedOS1MultiTokenIds.filter(item => item.valueOf() !== os1TokenIds[index]));
    }
  };

  const handleOS2SelectItem = (e: any, index: number) => {
    const { checked } = e.target;

    if (checked) {
      setIsOS2ItemsChecked([...os2ItemsChecked, index]);
      setSelectedOS2MultiTokenIds([...selectedOS2MultiTokenIds, os2TokenIds[index]]);
    } else {
      setIsOS2ItemsChecked(os2ItemsChecked.filter(item => item.valueOf() !== index));
      setSelectedOS2MultiTokenIds(selectedOS2MultiTokenIds.filter(item => item.valueOf() !== os2TokenIds[index]));
    }
  };

  const updateHoldings = async () => {
    try {
      const os1ComicBalanceBigNum = await os1ComicContract.balanceOf(account);
      setOS1ComicBalance(parseInt(os1ComicBalanceBigNum));
      const os2ComicBalanceBigNum = await os2ComicContract.balanceOf(account);
      setOS2ComicBalance(parseInt(os2ComicBalanceBigNum));
    } catch (error) {
      console.log(error);
    }
  };

  /** Return an array of tokenIds of the owner based on given balance*/
  const getTokenIdsArrayOfOwnerByIndexByContract = async (account: any, balance: number, contract: Contract) => {
    let tokenId;
    let tokenIds = [];

    for (let index = 0; index <= balance; index++) {
      try {
        tokenId = await contract.tokenOfOwnerByIndex(account, index);
        if (tokenId) tokenIds.push(parseInt(tokenId));
      } catch (error) {
        console.log(error);
      }
    }

    let sortedTokenIds = tokenIds.sort((a, b) => a - b);
    return sortedTokenIds;
  };

  const fetchTokenIdsByBalance = async (): Promise<void> => {
    setIsLoadingTokenIds(true);
    await getTokenIdsArrayOfOwnerByIndexByContract(account, os1ComicBalance, os1ComicContract).then(tokenIds =>
      setOS1TokenIds(tokenIds)
    );
    await getTokenIdsArrayOfOwnerByIndexByContract(account, os2ComicBalance, os2ComicContract).then(tokenIds =>
      setOS2TokenIds(tokenIds)
    );
    setIsLoadingTokenIds(false);
  };

  /**
   * @param tokenIds: array of tokenIds of OS1 and OS2
   * @param editions: array of edition number of OS1 and OS2
   */
  const mintOS3 = async (tokenIds: number[], editions: number[]) => {
    let mintResult = null;
    let mintState = null;
    let error = null;
    let os1ApprovalTxn;
    let os2ApprovalTxn;
    if (os1ComicBalance >= 1 || os2ComicBalance >= 1) {
      // before minting, set owner approval for OS3 operator (from OS1 and OS2)
      let os1HasApproval = await ownerHasApprovalOnOperator(account, OS3ContractAddress, os1ComicContract);
      let os2HasApproval = await ownerHasApprovalOnOperator(account, OS3ContractAddress, os2ComicContract);

      if (!os1HasApproval && editions.some(el => el === 1)) {
        try {
          os1ApprovalTxn = await os1ComicContract.setApprovalForAll(OS3ContractAddress, true);
          await os1ApprovalTxn.wait();
        } catch (err: any) {
          error = err;
          setMintError(err.message || err.code);
        }
      }
      if (!os2HasApproval && editions.some(el => el === 2)) {
        try {
          os2ApprovalTxn = await os2ComicContract.setApprovalForAll(OS3ContractAddress, true);
          await os2ApprovalTxn.wait();
        } catch (err: any) {
          error = err;
          setMintError(err.message || err.code);
        }
      }
      if (error) {
        setMintStatus(BurnStatus.BURN_FAILED);
        return;
      }
      // start minting
      try {
        mintState = await os3ComicContract.mintFromMul(tokenIds, editions);
      } catch (err: any) {
        error = err;
        setMintError(err.message || err.code);
      } finally {
        setMintTxnState(mintState);
      }

      // handle trasaction result
      error ? setMintStatus(BurnStatus.BURN_FAILED) : setMintStatus(BurnStatus.BURNING);
      if (error) return;

      try {
        mintResult = await mintState.wait();
      } catch (error: any) {
        console.log("mint has failed", error);
        setMintError(error.code);
        displayBurnFailedModal();
      } finally {
        setMintTxnState(mintState);
        if (!mintResult) return;
        if (mintResult.status === 1) {
          displayBurnSucceededModal();
          await updateHoldings();
        } else {
          displayBurnFailedModal();
        }
      }
    }
  };

  const handleMint = async () => {
    let OS1EditionsArray = Array(selectedOS1MultiTokenIds.length).fill(Edition.OS1_EDITION);
    let OS2EditionsArray = Array(selectedOS2MultiTokenIds.length).fill(Edition.OS2_EDITION);
    setOs1AmountToBurn(selectedOS1MultiTokenIds.length);
    setOs2AmountToBurn(selectedOS2MultiTokenIds.length);
    await mintOS3(selectedOS1MultiTokenIds.concat(selectedOS2MultiTokenIds), OS1EditionsArray.concat(OS2EditionsArray));
    // reset checkbox values
    setSelectedOS1MultiTokenIds([]);
    setSelectedOS2MultiTokenIds([]);
    setIsOS1ItemsChecked([]);
    setIsOS2ItemsChecked([]);
  };

  const BurnModalsComponent = (status: BurnStatus): ReactElement => {
    switch (status) {
      case BurnStatus.CONNECT_TO_BURN:
        return (
          <ConfirmationModal
            title={"LET'S SEE..."}
            message={"Connect your wallet to see if you <br> qualify to mint Origin Stories #3."}
            isOpen={mintModalOpen}
            hasButton={false}
            setOpen={setMintModalOpen}
            onConfirm={() => null}
            modalTheme={ACTION_STATUS.DEFAULT}
          >
            <div className={styles.connectBtnContainer}>
              <ButtonComponent
                isConnectButton={true}
                label="Connect"
                className={styles.connectWalletBtn}
                onConnect={async () => {
                  closeMintModal();
                }}
              />
            </div>
          </ConfirmationModal>
        );
      case BurnStatus.QUALIFY_TO_BURN:
        return (
          <ConfirmationModal
            title={"HELL YEAH!"}
            message={`You’re qualified to burn:<br> ${
              os1ComicBalance > 0 ? `<b> ${os1ComicBalance} Origin Stories Issue #1</b> NFT(s)` : ""
            } ${os1ComicBalance > 0 && os2ComicBalance > 0 ? "and<br>" : ""} ${
              os2ComicBalance > 0 ? `<b> ${os2ComicBalance} Origin Stories Issue #2</b> NFT(s) </b>` : ""
            } <br>for:<br><b>${os1ComicBalance + os2ComicBalance} Origin Stories Issue #3</b> NFT(s)`}
            isOpen={mintModalOpen}
            btnText={"proceed"}
            btnStyle={"transparent"}
            setOpen={setMintModalOpen}
            onConfirm={() => {
              fetchTokenIdsByBalance();
              displayReadyToBurnModal();
            }}
            caption={
              "Number of burns is calculated from your wallet’s holdings. You may choose how many you’d like to burn."
            }
            modalTheme={ACTION_STATUS.SUCCESS}
          />
        );
      case BurnStatus.NOT_QUALIFY_TO_BURN:
        return (
          <ConfirmationModal
            title={"UH OH!"}
            message={
              "Looks like your wallet doesn’t contain any:</br><b>Origin Stories Issue #1 or Origin Stories Issue #2</b> NFT(s)"
            }
            isOpen={mintModalOpen}
            btnText={"okay"}
            btnStyle={"transparent"}
            setOpen={setMintModalOpen}
            onConfirm={() => setMintModalOpen(false)}
            caption={
              "You must have Origin Stories Issue #1 NFTs or Origin Stories Issue #2 NFTs in your wallet in order to burn for Origin Stories Issue #3"
            }
            modalTheme={ACTION_STATUS.WARNING}
          />
        );
      case BurnStatus.READY_TO_BURN:
        return (
          <ConfirmationModal
            title={"BURN EM!"}
            message={isLoadingTokenIds ? "" : "How many <b>Origin Stories</b> NFT(s)<br>would you like to burn?"}
            isOpen={mintModalOpen}
            btnText={"CONFIRM"}
            btnStyle={"filled"}
            hasButton={!isLoadingTokenIds}
            buttonDisabled={
              selectedOS1MultiTokenIds.length + selectedOS2MultiTokenIds.length > MAX_MINT_AMOUNT ||
              selectedOS1MultiTokenIds.length + selectedOS2MultiTokenIds.length < 1
            }
            setOpen={setMintModalOpen}
            onConfirm={() => {
              if (!selectedOS1MultiTokenIds.length && !selectedOS2MultiTokenIds.length) return;
              displayReviewModal();
            }}
            caption={isLoadingTokenIds ? "" : `Max ${MAX_MINT_AMOUNT} tokens per transaction`}
            modalTheme={ACTION_STATUS.SUCCESS}
          >
            <>
              {isLoadingTokenIds ? (
                <>
                  <div>Loading your tokens...</div>
                  <br />
                  {Circles()}
                </>
              ) : (
                <div className={styles.scrollCheckboxPanel}>
                  {os1TokenIds.length > 0 && (
                    <>
                      <h4 className="text-primary-green">Origin Stories Issue #1</h4>
                      <div>
                        <CheckboxComponent
                          label="SELECT ALL"
                          className={styles.selectAll}
                          onChange={e => handleOS1SelectAll(e)}
                          isChecked={
                            selectedOS1MultiTokenIds.length >= 1 &&
                            selectedOS1MultiTokenIds.length === os1TokenIds.length
                          }
                        />
                        <div className={styles.smallCheckboxContainer}>
                          {os1TokenIds &&
                            os1TokenIds.map((tokenId, index) => (
                              <li key={index}>
                                <CheckboxComponent
                                  isChecked={os1ItemsChecked.includes(index)}
                                  className={styles.smallCheckbox}
                                  label={`# ${tokenId.toString()}`}
                                  onChange={e => handleOS1SelectItem(e, index)}
                                />
                              </li>
                            ))}
                        </div>
                      </div>
                    </>
                  )}
                  <div>
                    {os2TokenIds.length > 0 && (
                      <>
                        <h4 className="text-primary-green">Origin Stories Issue #2</h4>
                        <CheckboxComponent
                          label="SELECT ALL"
                          className={styles.selectAll}
                          onChange={e => handleOS2SelectAll(e)}
                          isChecked={
                            selectedOS2MultiTokenIds.length >= 1 &&
                            selectedOS2MultiTokenIds.length === os2TokenIds.length
                          }
                        />
                        <div className={styles.smallCheckboxContainer}>
                          {os2TokenIds.map((tokenId, index) => (
                            <li key={index}>
                              <CheckboxComponent
                                isChecked={os2ItemsChecked.includes(index)}
                                className={styles.smallCheckbox}
                                label={`# ${tokenId.toString()}`}
                                onChange={e => handleOS2SelectItem(e, index)}
                              />
                            </li>
                          ))}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              )}
              <div className={styles.maxReachedContainer}>
                {os1ItemsChecked.length + os2ItemsChecked.length > MAX_MINT_AMOUNT && (
                  <p className={styles.maxReachedText}>Max Mint Amount Reached</p>
                )}
              </div>
            </>
          </ConfirmationModal>
        );

      case BurnStatus.REVIEW:
        return (
          <ConfirmationModal
            title={"CONFIRM EM!"}
            message={"Are these the <b>Origin Stories</b> NFT(s)<br> you would like to burn?"}
            isOpen={mintModalOpen}
            btnText={"BURN"}
            btnStyle={"filled"}
            setOpen={setMintModalOpen}
            onConfirm={() => {
              displayFollowWalletInstructionsModal();
              handleMint();
            }}
            hasAltButton={true}
            handleAltClick={displayReadyToBurnModal}
            altBtnText={"Edit selection"}
            modalTheme={ACTION_STATUS.SUCCESS}
          >
            <>
              <div className={styles.scrollCheckboxPanel}>
                {selectedOS1MultiTokenIds.length ? (
                  <>
                    <h4 className="text-primary-green">Origin Stories Issue #1</h4>
                    <div>
                      <div className={clsx(styles.smallCheckboxContainer, styles.justifyCenter)}>
                        {selectedOS1MultiTokenIds.length &&
                          selectedOS1MultiTokenIds.map((tokenId, index) => (
                            <li key={index} className={clsx(CheckboxStyles.checkboxContainer, styles.reviewTile)}>
                              <div className={styles.reviewTileText}>#{tokenId.toString()}</div>
                            </li>
                          ))}
                      </div>
                    </div>
                  </>
                ) : null}
                <div>
                  {selectedOS2MultiTokenIds.length ? (
                    <>
                      <h4 className="text-primary-green">Origin Stories Issue #2</h4>
                      <div className={clsx(styles.smallCheckboxContainer, styles.justifyCenter)}>
                        {selectedOS2MultiTokenIds.map((tokenId, index) => (
                          <li key={index} className={clsx(CheckboxStyles.checkboxContainer, styles.reviewTile)}>
                            <div className={styles.reviewTileText}>#{tokenId.toString()}</div>
                          </li>
                        ))}
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
            </>
          </ConfirmationModal>
        );
      case BurnStatus.FOLLOW:
        return (
          <ConfirmationModal
            title={"Follow Wallet Instructions"}
            message={
              "This process may require multiple approvals depending on the number of Origin Stories editions selected, which can result in a brief pause between each step."
            }
            isOpen={mintModalOpen}
            hasButton={false}
            setOpen={setMintModalOpen}
            onConfirm={() => {}}
            modalTheme={ACTION_STATUS.SUCCESS}
          ></ConfirmationModal>
        );
      case BurnStatus.BURNING:
        return (
          <ConfirmationModal
            title={"BURNING"}
            message={"This may take a minute."}
            isOpen={mintModalOpen}
            btnText={"see tx on etherscan"}
            btnStyle={"transparent"}
            setOpen={setMintModalOpen}
            onConfirm={() => {
              window.open(
                `${IS_MAINNET ? etherscanUrl.mainnet : etherscanUrl.goerli}/${mintTxnState?.hash}`,
                "_blank",
                "noopener,noreferrer"
              );
            }}
            modalTheme={ACTION_STATUS.SUCCESS}
          >
            {Circles()}
          </ConfirmationModal>
        );
      case BurnStatus.BURN_SUCCEEDED:
        return (
          <ConfirmationModal
            title={"Congrats!"}
            message={`You successfully burned: <br> ${
              os1AmountToBurn > 0 ? `<b>${os1AmountToBurn} Origin Stories #1</b> NFT(s)` : ""
            }
            ${os1AmountToBurn > 0 && os2AmountToBurn > 0 ? "<br> and <br>" : ""}
            ${os2AmountToBurn > 0 ? `<b>${os2AmountToBurn} Origin Stories #2</b> NFT(s)` : ""} <br>for:<br> <b>${
              os1AmountToBurn + os2AmountToBurn
            } Origin Stories #3</b> NFT(s)`}
            isOpen={mintModalOpen}
            btnText={"Read the Comic"}
            btnStyle={"transparent"}
            setOpen={setMintModalOpen}
            onConfirm={() => navigate("/read-your-comic/origin-stories-3")}
            modalTheme={ACTION_STATUS.SUCCESS}
          >
            <div className={styles.btnContainer}>
              <button
                className={clsx(styles.modalPinkButton, "btn button-secondary")}
                onClick={() => {
                  os1ComicBalance + os2ComicBalance >= 1 ? displayQualifyToBurnModal() : displayNotQualifyToBurnModal();
                }}
              >
                Burn Some More
              </button>
            </div>
          </ConfirmationModal>
        );
      case BurnStatus.BURN_FAILED:
        return (
          <ConfirmationModal
            title={"Dammit!"}
            message={`The transaction failed (or was sped up).<br> <b>${mintError}</b>`}
            isOpen={mintModalOpen}
            btnText={"Close"}
            btnStyle={"transparent"}
            setOpen={setMintModalOpen}
            onConfirm={() => closeMintModal()}
            modalTheme={ACTION_STATUS.WARNING}
          >
            <div className={styles.btnContainer}>
              <button
                onClick={() =>
                  os1ComicBalance + os2ComicBalance >= 1 ? displayQualifyToBurnModal() : displayNotQualifyToBurnModal()
                }
                className={clsx(styles.modalPinkButton, "btn")}
              >
                Try again
              </button>
            </div>
          </ConfirmationModal>
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      <main className={styles.originStoriesPage}>
        <section className={clsx(styles.introSection, styles.os3IntroBackground)}>
          <Column className={styles.introColumnleft}>
            <img src={OS3NFTCover} alt="Origin stories 3 NFT cover" loading="lazy" className={styles.os3NFTCoverImg} />
          </Column>

          <Column className={styles.introColumnRight}>
            <h4 className={styles.introSubtitle}>Origin Stories: Issue #3</h4>
            <h1 className={styles.introTitle}>The Trouble with punks</h1>
            <p className="text-primary">
              Following the events of PUNKS 3, a frustrated Agent Ivanova returns to the office, meanwhile, the PUNKS
              crew hatches a plan to use Hilary, who shares a past with the FBI agent, to help get her off their back.
              But will the plan work or further enrage the fiery, hotshot Agent?
            </p>
            <div className={styles.introCTAButtons}>
              <HashLink to="#mint-now">
                <button className="btn btn-primary-green"> Burn Origin Stories to Claim</button>
              </HashLink>
              <a href={RaribleMarketplaceURL.originStories} target="_blank" rel="noreferrer">
                <button className="btn btn-secondary">View Collections</button>
              </a>
            </div>

            <div className={styles.socialLinks}></div>
          </Column>
        </section>

        <section className={styles.cardSection}>
          <Card className={clsx(styles.os3Card, styles.os3CardBackground)}>
            <Column className={styles.os3CardTextContainer}>
              <img src={OS3Logo} alt="origin stories logo" loading="lazy" className={styles.os3Logo} />
              <p className="text-primary">
                Origin Stories is Pixel Vault’s first deep-dive into individual characters and how their stories weave
                together across time. Pixel Vault will focus on developing the backstories of each main PUNKS character
                in OS1-16, one issue per character.
              </p>
            </Column>
          </Card>
        </section>

        <section className={styles.burnToClaimSectionContainer}>
          <Column className={styles.burnToClaimLeft}>
            <BoxComponent color="#14C918" className={styles.burnToClaimBox}>
              <div id="mint-now" className={styles.burnToClaimImagesContainer}>
                <div className={styles.burnFromContainer}>
                  <img
                    src={OS1ComicCover}
                    alt="origin stories 3 comic cover thumbnail"
                    loading="lazy"
                    className={styles.os3ComicImageThumbnail}
                  />
                  <img src={FireImg} alt="fire decoration" loading="lazy" className={styles.fireLeft} />
                  <img src={FireImg} alt="fire decoration" loading="lazy" className={styles.fireRight} />
                </div>

                <FontAwesomeIcon className={styles.rightArrowIcon} icon={faArrowRight} />
                <img
                  src={OS3NFTCover}
                  loading="lazy"
                  alt="origin stories 3 nft cover thumbnail"
                  className={styles.os3NFTComicCoverThumbnail}
                />
              </div>

              <h3 className={styles.burnToClaimTitle}>Burn To Claim</h3>
              <p className="text-primary">
                Burn any of your Origin Stories NFTs in exchange for Origin Stories #4 NFTs.
              </p>
              <div className={styles.btnContainer}>
                {!eventClosed && (
                  <button
                    className="btn btn-primary-green"
                    onClick={() => {
                      account
                        ? hasBalance
                          ? displayQualifyToBurnModal()
                          : displayNotQualifyToBurnModal()
                        : displayConnectModal();
                      openMintModal();
                    }}
                  >
                    Burn to claim
                  </button>
                )}
                <button className="btn btn-secondary" onClick={() => navigate("/read-your-comic/origin-stories-3")}>
                  Read it first
                </button>
              </div>
              <p className={clsx("text-primary", styles.captionText)}>
                Holders of Origin Stories NFTs have access to read Origin Stories #3 before burning.
              </p>
            </BoxComponent>
          </Column>
          <Column className={styles.burnToClaimRight}>
            <h2 className="text-light">Burn Your way through set 1</h2>
            <p className={clsx("text-primary", styles.burnText)}>
              Any Origin Stories NFT may be burned in exchange for the currently available Origin Stories NFT (Issue #3,
              at the moment) as a free claim. Origin Stories Set 1 will consist of Issues 1-8 and will each be
              accessible via Set 1 burns only, keeping the total supply of Origin Stories Set 1 at the original Issue 1
              supply of 5,902.
            </p>
            <div className={styles.cardImageGalleryContainer}>
              <ImageCard
                image={OS1ComicCover}
                title={`Issue #1 <br> ${os1ComicTotalSupply} / 5902 NFTS`}
                description={`${((os1ComicTotalSupply / 5902) * 100).toFixed(2)}% of the Origin Stories Collection`}
              >
                <div className={styles.progressBarContainer}>
                  <ProgressBarComponent
                    minted={os1ComicTotalSupply}
                    total={5902}
                    className={styles.progressBar}
                    showRemaining={false}
                    barColor="#444A5C"
                  />
                </div>
              </ImageCard>
              <ImageCard
                image={OS2ComicCover}
                title={`Issue #2 <br> ${os2ComicTotalSupply} / 5902 NFTS`}
                description={`${((os2ComicTotalSupply / 5902) * 100).toFixed(2)}% of the Origin Stories Collection`}
              >
                <div className={styles.progressBarContainer}>
                  <ProgressBarComponent
                    minted={os2ComicTotalSupply}
                    total={5902}
                    className={styles.progressBar}
                    showRemaining={false}
                    barColor="#444A5C"
                  />
                </div>
              </ImageCard>
              <ImageCard
                image={OS3NFTCover}
                title={`Issue #3 <br> ${os3ComicTotalSupply} / 5902 NFTS`}
                description={`${((os3ComicTotalSupply / 5902) * 100).toFixed(2)}% of the Origin Stories Collection`}
              >
                <div className={styles.progressBarContainer}>
                  <ProgressBarComponent
                    minted={os3ComicTotalSupply}
                    total={5902}
                    className={styles.progressBar}
                    showRemaining={false}
                    barColor="#444A5C"
                  />
                </div>
              </ImageCard>
            </div>
          </Column>
        </section>

        {BurnModalsComponent(mintStatus)}
      </main>

      <FooterSection />
    </>
  );
};

export default OS3Page;
