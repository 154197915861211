import React, { useEffect } from "react";
import { useState } from "react";
import { axiosInstancePunksComic } from "axiosHelper/axios";
import { Contract, ethers } from "ethers";
import Web3Modal from "web3modal";
import CoinbaseWalletSDK from "@coinbase/wallet-sdk";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { networks } from "utils/networkConstants";

export const IS_MAINNET = true; // TO TOGGLE WHEN LAUNCHING
const providerOptions = {
  coinbasewallet: {
    package: CoinbaseWalletSDK,
    options: {
      appName: "Punkscomic",
      infuraId: process.env.REACT_APP_INFURA_KEY,
      chainId: IS_MAINNET ? 1 : 5,
    },
  },
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      infuraId: process.env.REACT_APP_INFURA_KEY,
    },
  },
};

const DataContext = React.createContext<any | null>(null);

const web3modal = new Web3Modal({
  providerOptions,
  disableInjectedProvider: false,
  cacheProvider: false,
  network: IS_MAINNET ? networks.mainnet.name : networks.goerli.name,
  theme: "dark",
});

function useData() {
  const [isWalletSuspicious, setIsWalletSuspicious] = useState(false);

  /** contracts */
  const [apeCoinContract, setApeCoinContract] = useState<Contract>();
  const [apeComicContract, setApeComicContract] = useState<Contract>();
  const [apeMadnessContract, setApeMadnessContract] = useState<Contract>();

  /** balances */
  const [comic1Balance, setComic1Balance] = useState<number>();
  const [comic3Balance, setComic3Balance] = useState<number>();
  const [specialComic3Balance, setSpecialComic3Balance] = useState<number>();
  const [holoComic3Balance, setHoloComic3Balance] = useState<number>();
  const [mp1Balance, setMP1Balance] = useState<number>();
  const [metaheroGenBalance, setMetaheroGenBalance] = useState<number>();
  const [metaheroCoresBalance, setMetaheroCoresBalance] = useState<number>();
  const [foundersDAOBalance, setFoundersDAOBalance] = useState<number>();
  const [eliteApeCoinBalance, setEliteApeCoinBalance] = useState<number>();
  const [apeComicBalance, setApeComicBalance] = useState<number>(0);

  /** network provider, wallet and account */
  const [account, setAccount] = useState<any>();
  const [provider, setProvider] = useState<ethers.providers.Web3Provider>(window.ethereum);
  const [chainId, setChainId] = useState<number>();
  const [network, setNetwork] = useState<number>();
  const [signer, setSigner] = useState<ethers.providers.JsonRpcSigner>();
  const [isMainnet, setIsMainnet] = useState(false);

  const connectWallet = async () => {
    try {
      await web3modal.clearCachedProvider();
      const walletSelection = await web3modal.connect();
      const provider = new ethers.providers.Web3Provider(walletSelection);
      const signer = provider.getSigner();
      setProvider(provider);
      setSigner(signer);
      provider.listAccounts().then(accounts => setAccount(accounts[0]));
    } catch (error) {
      console.warn("ERROR CONNECTING TO METAMASK");
    }
  };

  const reset = () => {
    web3modal.clearCachedProvider();
    setProvider(window.ethereum);
    setSigner(undefined);
    setAccount(undefined);
  };

  // verify suspicious wallet after connecting to the wallet
  useEffect(() => {
    verifySuspiciousWallet(account);
    // eslint-disable-next-line
  }, [connectWallet, account, provider, signer]);

  // provider event listeners and handling
  useEffect(() => {
    window.localStorage.removeItem("Cart_Items");

    if (!provider) return;

    provider.on("accountsChanged", (accounts: string[]) => {
      reset();
    });

    provider.on("disconnect", async () => {
      reset();
    });

    provider.on("chainChanged", (_hexChainId: React.SetStateAction<number | undefined>) => {
      setChainId(_hexChainId);
    });
  }, [provider]);

  const verifySuspiciousWallet = async (account: any) => {
    let userWallet = account;
    if (!account) return;
    await axiosInstancePunksComic.get(`/audit-wallet.php?addr=${userWallet}`).then(data => {
      let walletData = data.data[0];
      walletData.rating === "unknown" || "lowRisk" ? setIsWalletSuspicious(false) : setIsWalletSuspicious(true);
    });
  };

  return {
    account,
    setAccount,
    provider,
    setProvider,
    signer,
    setSigner,
    chainId,
    isWalletSuspicious,
    comic1Balance,
    setComic1Balance,
    mp1Balance,
    setMP1Balance,
    comic3Balance,
    setComic3Balance,
    specialComic3Balance,
    setSpecialComic3Balance,
    holoComic3Balance,
    setHoloComic3Balance,
    metaheroGenBalance,
    setMetaheroGenBalance,
    metaheroCoresBalance,
    setMetaheroCoresBalance,
    foundersDAOBalance,
    setFoundersDAOBalance,
    eliteApeCoinBalance,
    setEliteApeCoinBalance,
    apeComicBalance,
    setApeComicBalance,
    apeCoinContract,
    setApeCoinContract,
    apeComicContract,
    setApeComicContract,
    apeMadnessContract,
    setApeMadnessContract,
    connectWallet,
    isMainnet,
    setIsMainnet,
    setNetwork,
    network,
  };
}

export const DataProvider: React.FC<any> = ({ children }) => {
  const data = useData();

  return <DataContext.Provider value={data}>{children}</DataContext.Provider>;
};

export default function DataConsumer() {
  return React.useContext(DataContext);
}
