import React, { ReactElement, useContext, useEffect } from "react";

import os4ComicCover from "images/os4NFTCover.webp";
import DescriptionSection from "components/DescriptionSection/DescriptionSection";
import FooterSection from "components/FooterSection/FooterSection";
import useData from "context/dataContext";
import { OSContext } from "context/osContext";
import { RaribleMarketplaceURL, TheComics } from "ui/consts";
import PdfViewer from "components/PdfViewer/PdfViewer";

import styles from "./ReadOS3Comic.module.scss";

const ReadOS3Comic = (): ReactElement => {
  const { account } = useData();
  const { os1ComicBalance, os2ComicBalance, os3ComicBalance, os4ComicBalance } = useContext(OSContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <main>
        {account ? (
          os1ComicBalance >= 1 || os2ComicBalance >= 1 || os3ComicBalance >= 1 || os4ComicBalance >= 1 ? (
            <div>
              <h1 className={styles.originStoriesHeader}>{TheComics.os4.title}</h1>
              <PdfViewer showTitle={false} pdfUrl={TheComics.os4.issuuLink} />
            </div>
          ) : (
            <DescriptionSection
              title="Origin Stories 4"
              content="Sorry, you do not qualify to read this comic."
              className={styles.descriptionContainer}
              buttonText="View the collection"
              buttonClassName="btn-secondary"
              captionText="Only holders of Origin Stories NFT(s) have access to read Origin Stories 4"
              image={os4ComicCover}
              imgClassName={styles.opencomicImage}
              buttonHandler={() => window.open(RaribleMarketplaceURL.originStories, "_blank", "noopener,noreferrer")}
              alt="Origin Stories 4 comic"
              size="large"
            />
          )
        ) : (
          <DescriptionSection
            title="Origin Stories 4"
            content="Connect your wallet to see if you qualify to read this comic."
            className={styles.descriptionContainer}
            buttonText="Connect To Read"
            buttonClassName="btn btn-primary-white"
            isConnectBtn={true}
            captionText="Only holders of Origin Stories NFT(s) have access to read Origin Stories 4"
            image={os4ComicCover}
            imgClassName={styles.opencomicImage}
            alt="Origin Stories 4 comic"
            size="large"
          />
        )}
      </main>
      <FooterSection />
    </>
  );
};

export default ReadOS3Comic;
