import { ReactElement } from "react";
import clsx from "clsx";

import "./DescriptionSection.css";
import ContentDescription from "../ContentDescription/ContentDescription";

type Props = {
  title: string;
  content: string;
  buttonText: string;
  buttonClassName?: string;
  buttonHandler?: () => void;
  isConnectBtn?: boolean;
  captionText?: string;
  className?: string;
  isReversedColumn?: boolean;
  id?: string;
  image: string;
  imgClassName?: string;
  size?: string;
  alt?: string;
  containerBgImage?: boolean;
};

const DescriptionSection = ({
  title,
  content,
  buttonText,
  buttonClassName,
  buttonHandler,
  isConnectBtn,
  captionText,
  className: classNameProp,
  isReversedColumn = false,
  id: idProp,
  image,
  imgClassName: imgClassNameProp,
  size = "normal",
  alt,
  containerBgImage: bgImage = false,
  ...props
}: Props): ReactElement => {
  const className = clsx(
    "description-container",
    classNameProp,
    isReversedColumn && "reverse",
    size === "large" ? size : "normal",
    bgImage && "description-container-background"
  );

  return (
    <section {...props} id={idProp} className={className}>
      <ContentDescription
        title={title}
        content={content}
        buttonText={buttonText}
        buttonClassName={buttonClassName}
        isConnectBtn={isConnectBtn}
        onClick={buttonHandler}
        captionText={captionText}
        className="description-column"
      />
      <div className={imgClassNameProp ? imgClassNameProp : clsx("image-column", size)}>
        <img src={image} className={imgClassNameProp} loading="lazy" alt={alt} />
      </div>
    </section>
  );
};

export default DescriptionSection;
