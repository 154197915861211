import React, { ReactElement, ReactFragment, ReactNode } from "react";
import clsx from "clsx";

type Props = {
	children: ReactFragment | ReactNode;
	className?: string;
	id?: string;
};

const Column = ({
	children,
	className: classNameProp,
	id: idProp,
	...props
}: Props): ReactElement => {
	const className = clsx("column-container", classNameProp);
	return (
		<div id={idProp} className={className}>
			{children}
		</div>
	);
};

export default Column;
