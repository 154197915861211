import PunksLogo from "images/PunksLogo.png";
import Twitter from "images/Twitter.webp";
import Discord from "images/Discord.webp";
import { ReactElement, useState } from "react";

import "./Navbar.css";

type Props = {
  transparent?: boolean;
};
const Navbar = ({ transparent }: Props): ReactElement => {
  const [scrollPosition, setScrollPosition] = useState(window.scrollY);
  const [hasNavbar, setHasNavbar] = useState(scrollPosition === 0 || transparent ? false : true);

  const handleMenuClick = () => {
    const hamburger = document.querySelector(".hamburger");
    const navbarMenu = document.querySelector(".navbar-menu");

    hamburger?.classList.toggle("active");
    navbarMenu?.classList.toggle("active");
  };

  document.addEventListener("scroll", e => {
    setScrollPosition(window.scrollY);
    scrollPosition <= 70 ? setHasNavbar(false) : setHasNavbar(true);
  });

  return (
    <header>
      <>
        <nav className={hasNavbar ? "navbar-background" : "navbar-transparent"}>
          <a href="https://punkscomic.com/">
            <img className="navbar-punks-logo" src={PunksLogo} alt="The Punks Logo" />
          </a>

          <ul onClick={handleMenuClick} className="navbar-menu">
            <li className="navbar-item">
              <a className="navbar-link" href="https://punkscomic.com/meetthepunks.html">
                The Punks
              </a>
            </li>
            <li className="navbar-item">
              <a className="navbar-link" href="https://punkscomic.com/the-comics.html">
                The Comics
              </a>
            </li>
            <li className="navbar-item">
              <a className="navbar-link" href="https://punkscomic.com/faq.html">
                FAQS
              </a>
            </li>
            <li className="navbar-item item-stake">
              <a className="navbar-link link-stake" href="https://punkscomic.com/dashboard.html">
                Stake
              </a>
            </li>
            <li style={{ marginRight: "-15px" }} className="navbar-item">
              <a className="navbar-link" href="https://twitter.com/punkscomic">
                <img src={Twitter} className="social-media-img" alt="Pixel Vault Twitter" />
              </a>
            </li>
            <li className="navbar-item">
              <a className="navbar-link" href="https://discord.com/invite/pixelvault">
                <img src={Discord} className="social-media-img" alt="Pixel Vault Discord" />
              </a>
            </li>
          </ul>

          <div onClick={handleMenuClick} className="hamburger">
            <span className="bar"></span>
            <span className="bar"></span>
            <span className="bar"></span>
          </div>
        </nav>
      </>
    </header>
  );
};

export default Navbar;
