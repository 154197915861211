import React, { ReactElement, useEffect, useState } from "react";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { HashLink as Link } from "react-router-hash-link";
import { useNavigate } from "react-router-dom";

import { BurnStatus, ClaimStatus, Signature, MAX_MINT_AMOUNT, ELITE_APE_TOKEN_ID } from "../consts";
import BoxComponent from "components/BoxComponent/BoxComponent";
import FooterSection from "components/FooterSection/FooterSection";
import Column from "components/Section/Column/Column";
import ContentDescription from "components/ContentDescription/ContentDescription";
import Section from "components/Section/Section";
import ApeLogo from "images/ApeLogo.svg";
import ApeEliteCoin from "images/ApeEliteCoin.png";
import ApeOutOnTheTown from "images/ApeOutOnTheTown.png";
import ApeOutOnTheTownComic from "images/ApeOutOnTheTownComic.webp";
import ApeMadness from "images/ApeMadness.webp";
import ApeOpenComic from "images/ApeOpenComic.webp";
import { Circles } from "components/Loaders/circles.js";
import DescriptionSection from "components/DescriptionSection/DescriptionSection";
import useData, { IS_MAINNET } from "context/dataContext";
import ButtonComponent from "components/ButtonComponent/ButtonComponent";
import ConfirmationModal, { ACTION_STATUS } from "components/ConfirmationModal/ConfirmationModal";
import CounterComponent, { COUNTER_ACTION_TYPE } from "components/CounterComponent/CounterComponent";
import { etherscanUrl } from "utils/networkConstants";

import testnetApeMadnessSignaturesJson from "blockchain/signatures/testnet-signatures-ape-madness.json";
import mainnetApeMadnessSignaturesJson from "blockchain/signatures/mainnet-signatures-ape-madness.json";

import "./ApeTownPage.css";

const apeMadnessSignaturesJson = IS_MAINNET ? mainnetApeMadnessSignaturesJson : testnetApeMadnessSignaturesJson;

const isEventClosed = true;

interface TransactionResult {
  code: number;
  message: string;
  stack: string;
  hash: string;
}

const ApeTownPage = (): ReactElement => {
  const navigate = useNavigate();
  const {
    account,
    apeComicContract,
    apeMadnessContract,
    apeCoinContract,
    setEliteApeCoinBalance,
    eliteApeCoinBalance,
    setApeComicBalance,
    isMainnet,
  } = useData();
  const [burnStatus, setBurnStatus] = useState<BurnStatus>(BurnStatus.QUALIFY_TO_BURN);
  const [claimStatus, setClaimStatus] = useState<ClaimStatus>(ClaimStatus.QUALIFY_TO_CLAIM);
  const [burnModalOpen, setBurnModalOpen] = useState(false);
  const [claimModalOpen, setClaimModalOpen] = useState(false);
  const [apeComicAmountToMint, setApeComicAmountToMint] = useState<number>(1);
  const [mintTxnState, setMintTxnState] = useState<TransactionResult>();
  const [claimTxnState, setClaimTxnState] = useState<TransactionResult>();
  const [mintError, setMintError] = useState<any>("");
  const [claimError, setClaimError] = useState<any>("");

  const openMintModal = (): void => setBurnModalOpen(true);
  const openClaimModal = (): void => setClaimModalOpen(true);
  const handleConfirm = (): void => setBurnModalOpen(false);

  const updateApeHoldings = async () => {
    try {
      const eliteApeCoinBalanceBigNum = await apeCoinContract.balanceOf(account, ELITE_APE_TOKEN_ID);
      const eliteApeCoinCount = parseInt(eliteApeCoinBalanceBigNum);
      setEliteApeCoinBalance(eliteApeCoinCount);
      const apeComicBalanceBigNum = await apeComicContract.balanceOf(account);
      const apeComicAmount = parseInt(apeComicBalanceBigNum);
      setApeComicBalance(apeComicAmount);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (burnStatus.valueOf() !== BurnStatus.BURN_SUCCEEDED.valueOf()) {
      if (eliteApeCoinBalance > 0) {
        setBurnStatus(BurnStatus.QUALIFY_TO_BURN);
        const maxAmount = Math.min(MAX_MINT_AMOUNT, eliteApeCoinBalance);
        let maxMintAmount = Math.min(eliteApeCoinBalance, maxAmount);
        setApeComicAmountToMint(maxMintAmount);
      } else {
        setBurnStatus(BurnStatus.NOT_QUALIFY_TO_BURN);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eliteApeCoinBalance]);

  const BurnModalsComponent = (status: BurnStatus): ReactElement => {
    switch (status) {
      case BurnStatus.QUALIFY_TO_BURN:
        return (
          <ConfirmationModal
            title={"HELL YEAH!"}
            message={`You’re qualified to burn:<br> <b>${eliteApeCoinBalance} Elite Ape Entry Coin(s)</b><br>for:<br><b>${eliteApeCoinBalance} Apes Out On The Town</b> NFT(s)`}
            isOpen={burnModalOpen}
            btnText={"proceed"}
            btnStyle={"transparent"}
            setOpen={setBurnModalOpen}
            onConfirm={() => {
              setBurnStatus(BurnStatus.READY_TO_BURN);
            }}
            caption={
              "Number of burns is calculated from your wallet’s holdings. You may choose how many you’d like to burn."
            }
            modalTheme={ACTION_STATUS.SUCCESS}
          />
        );
      case BurnStatus.NOT_QUALIFY_TO_BURN:
        return (
          <ConfirmationModal
            title={"UH OH!"}
            message={"Looks like your wallet doesn’t contain any:</br><b>Elite Ape Entry Coins</b>"}
            isOpen={burnModalOpen}
            btnText={"View the collection"}
            btnStyle={"transparent"}
            setOpen={setBurnModalOpen}
            onConfirm={() =>
              window.open(`https://opensea.io/collection/apes-out-on-the-town`, "_blank", "noopener,noreferrer")
            }
            caption={
              "You must have Elite Ape Entry Coin(s) in your wallet in order to burn for Apes Out On The Town NFT(s)."
            }
            modalTheme={ACTION_STATUS.WARNING}
          />
        );
      case BurnStatus.READY_TO_BURN:
        return (
          <ConfirmationModal
            title={"BURN EM!"}
            message={"How many <b>Elite Ape Entry Coins</b><br>would you like to burn?"}
            isOpen={burnModalOpen}
            btnText={"burn"}
            btnStyle={"filled"}
            setOpen={setBurnModalOpen}
            onConfirm={() => mintApeComic(apeComicAmountToMint)}
            caption={`Max ${MAX_MINT_AMOUNT} tokens per transaction`}
            modalTheme={ACTION_STATUS.SUCCESS}
          >
            <div className="counter">
              <CounterComponent
                actionType={COUNTER_ACTION_TYPE.BURN}
                maxAmount={Math.min(MAX_MINT_AMOUNT, eliteApeCoinBalance)}
                onSelect={maxAmount => {
                  let maxMintAmount = Math.min(eliteApeCoinBalance, maxAmount);
                  if (maxMintAmount === 0) maxMintAmount = 1;
                  setApeComicAmountToMint(maxMintAmount);
                }}
              />
            </div>
          </ConfirmationModal>
        );
      case BurnStatus.BURNING:
        return (
          <ConfirmationModal
            title={"BURNING"}
            message={"This may take a minute."}
            isOpen={burnModalOpen}
            btnText={"see tx on etherscan"}
            btnStyle={"transparent"}
            setOpen={setBurnModalOpen}
            onConfirm={() =>
              window.open(
                `${isMainnet ? etherscanUrl.mainnet : etherscanUrl.goerli}/${mintTxnState?.hash}`,
                "_blank",
                "noopener,noreferrer"
              )
            }
            modalTheme={ACTION_STATUS.SUCCESS}
          >
            {Circles()}
          </ConfirmationModal>
        );
      case BurnStatus.BURN_SUCCEEDED:
        return (
          <ConfirmationModal
            title={"Congrats!"}
            message={`You successfully burned: <br> <b>${apeComicAmountToMint} Elite Ape Entry Coin(s)</b><br>for:<br> <b>${apeComicAmountToMint} Apes Out On The Town</b> NFT(s)`}
            isOpen={burnModalOpen}
            btnText={"Read the Comic"}
            btnStyle={"transparent"}
            setOpen={setBurnModalOpen}
            onConfirm={() => navigate("/read-your-comic/apes-out-on-the-town")}
            modalTheme={ACTION_STATUS.SUCCESS}
          ></ConfirmationModal>
        );
      case BurnStatus.BURN_FAILED:
        return (
          <ConfirmationModal
            title={"Dammit!"}
            message={`The transaction failed (or was sped up).<br> <b>${mintError}</b>`}
            isOpen={burnModalOpen}
            btnText={"Close"}
            btnStyle={"transparent"}
            setOpen={setBurnModalOpen}
            onConfirm={() => handleConfirm()}
            modalTheme={ACTION_STATUS.WARNING}
          ></ConfirmationModal>
        );
      default:
        return <></>;
    }
  };

  const mintApeComic = async (amountToMint: number) => {
    let mintResult = null;
    let mintState = null;
    let error = null;

    if (eliteApeCoinBalance) {
      try {
        mintState = await apeComicContract.mint(amountToMint);
      } catch (err: any) {
        error = err;
        setMintError(err.message || err.code);
      } finally {
        setMintTxnState(mintState);
      }

      error ? setBurnStatus(BurnStatus.BURN_FAILED) : setBurnStatus(BurnStatus.BURNING);
      if (error) return;

      try {
        mintResult = await mintState.wait();
      } catch (error: any) {
        console.log("mint has failed", error);
        setMintError(error.code);
        setBurnStatus(BurnStatus.BURN_FAILED);
      } finally {
        setMintTxnState(mintState);
        if (!mintResult) return;
        if (mintResult.status === 1) {
          setBurnStatus(BurnStatus.BURN_SUCCEEDED);
          await updateApeHoldings();
        } else {
          setBurnStatus(BurnStatus.BURN_FAILED);
        }
      }
    }
  };

  const handleMint = async () => {
    eliteApeCoinBalance > 0 ? setBurnStatus(BurnStatus.QUALIFY_TO_BURN) : setBurnStatus(BurnStatus.NOT_QUALIFY_TO_BURN);
    openMintModal();
  };

  /** Claim */
  const handleClaimApeMadness = async () => {
    // validate claim eligibility of the user using their wallet address
    let signatureObj = apeMadnessSignaturesJson.find(data => data.wallet === account);
    let signature = signatureObj?.sig;
    if (signature) {
      let hasClaimed: boolean = await apeMadnessContract.minted(account);
      signature && !hasClaimed
        ? setClaimStatus(ClaimStatus.QUALIFY_TO_CLAIM)
        : setClaimStatus(ClaimStatus.NOT_QUALIFY_TO_CLAIM);
    } else {
      setClaimStatus(ClaimStatus.NOT_QUALIFY_TO_CLAIM);
    }
    openClaimModal();
  };

  const claimApeMadness = async (signatures: Signature[]) => {
    let claimResult = null;
    let claimState = null;
    let error = null;

    let signatureObj = signatures.find(data => data.wallet === account);
    if (!signatureObj) return;
    let signature = signatureObj.sig;

    if (signature) {
      try {
        claimState = await apeMadnessContract.mint(signature);
      } catch (err: any) {
        console.log(err);
        error = err;
        setClaimError(error.message || error.code);
      } finally {
        setClaimStatus(ClaimStatus.CLAIMING);
        setClaimTxnState(claimState);
      }

      error ? setClaimStatus(ClaimStatus.CLAIM_FAILED) : setClaimStatus(ClaimStatus.CLAIMING);
      if (error) return;

      try {
        claimResult = await claimState.wait();
      } catch (error: any) {
        setClaimError(error.message || error.code);
        console.log("claim has failed", error);
        setClaimStatus(ClaimStatus.CLAIM_FAILED);
      } finally {
        setClaimTxnState(claimState);
        if (!claimResult) return;
        claimResult.status === 1
          ? setClaimStatus(ClaimStatus.CLAIM_SUCCEEDED)
          : setClaimStatus(ClaimStatus.CLAIM_FAILED);
      }
    }
  };

  const ClaimModalsComponent = (status: ClaimStatus): ReactElement => {
    switch (status) {
      case ClaimStatus.QUALIFY_TO_CLAIM:
        return (
          <ConfirmationModal
            title={"HELL YEAH!"}
            message={`You're qualified to claim an<br><b>Ape Madness NFT</b>`}
            isOpen={claimModalOpen}
            btnText={"claim"}
            btnStyle={"filled"}
            setOpen={setClaimModalOpen}
            onConfirm={() => claimApeMadness(apeMadnessSignaturesJson)}
            caption={"Participants of the Ape Madness tournament can claim 1 free Ape Madness NFT."}
            modalTheme={ACTION_STATUS.SUCCESS}
          />
        );
      case ClaimStatus.NOT_QUALIFY_TO_CLAIM:
        return (
          <ConfirmationModal
            title={"OH NO!"}
            message={`Looks like your wallet doesn’t qualify to claim an Ape Madness NFT, or you have already claimed.`}
            isOpen={claimModalOpen}
            btnText={"view the collection"}
            btnStyle={"transparent"}
            setOpen={setClaimModalOpen}
            onConfirm={() => window.open(`https://opensea.io/collection/ape-madness`, "_blank", "noopener,noreferrer")}
            caption={"Only participants of the Ape Madness tournament are eligible to claim a single Ape Madness NFT."}
            modalTheme={ACTION_STATUS.WARNING}
          />
        );
      case ClaimStatus.CLAIMING:
        return (
          <ConfirmationModal
            title={"Claiming"}
            message={`This may take a minute.`}
            isOpen={claimModalOpen}
            btnText={"SEE TX ON ETHERSCAN"}
            btnStyle={"transparent"}
            setOpen={setClaimModalOpen}
            onConfirm={() =>
              window.open(
                `${isMainnet ? etherscanUrl.mainnet : etherscanUrl.rinkeby}/${claimTxnState?.hash}`,
                "_blank",
                "noopener,noreferrer"
              )
            }
            modalTheme={ACTION_STATUS.SUCCESS}
          >
            {Circles()}
          </ConfirmationModal>
        );
      case ClaimStatus.CLAIM_SUCCEEDED:
        return (
          <ConfirmationModal
            title={"Congrats!"}
            message={`You successfully claimed your <br> <b>Ape Madness NFT</b>`}
            isOpen={claimModalOpen}
            btnText={"close"}
            btnStyle={"transparent"}
            setOpen={setClaimModalOpen}
            onConfirm={() => setClaimModalOpen(false)}
            modalTheme={ACTION_STATUS.SUCCESS}
          />
        );
      case ClaimStatus.CLAIM_FAILED:
        return (
          <ConfirmationModal
            title={"Dammit!"}
            message={`The transaction failed (or was sped up). <br> <b>${claimError}</b>`}
            isOpen={claimModalOpen}
            btnText={"close"}
            btnStyle={"transparent"}
            setOpen={setClaimModalOpen}
            onConfirm={() => handleConfirm()}
            modalTheme={ACTION_STATUS.WARNING}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      <main className="ape-town-page">
        <div className="ape-banner"></div>
        {!isEventClosed && (
          <Link to="#mint-now" className="divider-button">
            Mint Your Comic
          </Link>
        )}

        {isEventClosed ? (
          <section className="ape-out-on-the-town horizontal">
            <Section>
              <>
                <Column>
                  <img src={ApeOutOnTheTown} alt="ape out on the town" loading="lazy" className="ape-open-comic-img" />
                </Column>
              </>
            </Section>
            <div className="ape-out-on-the-town-text-container">
              <h1 className="header-primary">
                <img src={ApeLogo} alt="ape logo" loading="lazy" className="ape-logo" />
              </h1>
              <p className="text-primary ape-out-on-the-town-text">
                In their constant pursuit of relieving boredom, a small crew of apes travel to Origin City for a night
                out on the town. Readers of PUNKS #2 will recognize the familiar faces of Hanzo, Lone Star, Kiki, Biz,
                Tropo, Gold-Rilla, King Blackbored, and BBA as they all try to help a blacked-out Biz retrace his steps
                from the previous night’s adventures.
              </p>
              <button
                className="btn btn-secondary"
                onClick={() =>
                  window.open(`https://opensea.io/collection/apes-out-on-the-town`, "_blank", "noopener,noreferrer")
                }
              >
                View The Collection
              </button>
            </div>
          </section>
        ) : (
          <section className="ape-out-on-the-town">
            <div>
              <h1 className="header-primary">
                <img src={ApeLogo} alt="ape logo" loading="lazy" className="ape-logo" />
              </h1>
              <p className="text-primary ape-out-on-the-town-text">
                In their constant pursuit of relieving boredom, a small crew of apes travel to Origin City for a night
                out on the town. Readers of PUNKS #2 will recognize the familiar faces of Hanzo, Lone Star, Kiki, Biz,
                Tropo, Gold-Rilla, King Blackbored, and BBA as they all try to help a blacked-out Biz retrace his steps
                from the previous night’s adventures.
              </p>
            </div>

            <Section>
              <>
                <Column>
                  <img src={ApeOutOnTheTown} alt="ape out on the town" loading="lazy" className="ape-open-comic-img" />
                </Column>

                <Column className="burn-to-claim-column">
                  <div id="mint-now" className="burn-to-claim-container">
                    <BoxComponent color="#14C918" className="burn-to-claim-box">
                      <div className="burn-to-claim-images-container">
                        <img
                          src={ApeEliteCoin}
                          alt="elite ape entry coin"
                          loading="lazy"
                          className="elite-ape-coin-img"
                        />
                        <FontAwesomeIcon className="right-arrow-icon" icon={faArrowRight} />
                        <img
                          src={ApeOutOnTheTownComic}
                          loading="lazy"
                          alt="ape out on the town comic"
                          className="ape-out-on-the-town-comic-img"
                        />
                      </div>

                      <ContentDescription
                        title="burn to claim"
                        content="Burn your Elite Ape Entry Coins for Apes Out On The Town Comic NFTs."
                        className="burn-to-claim-text"
                        isConnectBtn={false}
                      />

                      {account ? (
                        <button
                          className="btn btn-primary-green burn-to-claim-btn"
                          onClick={async () => await handleMint()}
                        >
                          Burn to claim
                        </button>
                      ) : (
                        <div className="connect-btn-container">
                          <ButtonComponent isConnectButton={true} label="Connect Wallet" />
                        </div>
                      )}
                    </BoxComponent>

                    <p className="burn-to-claim-close-date-info-text">burn closes on July 28 at 5 pm ET</p>
                  </div>
                </Column>
              </>
            </Section>
          </section>
        )}

        <DescriptionSection
          title="Read Your Comic"
          content="Written by Josh Blaylock and illustrated by Chris Wahl, Apes Out on the Town is a unique, new adventure for the Elite Apes! Trade your Elite Ape Entry Coin for the Comic NFT to read."
          buttonText="Access Apes Out on The Town"
          buttonClassName="btn-secondary"
          buttonHandler={() => navigate("/read-your-comic/apes-out-on-the-town")}
          captionText="Only holders of Apes Out On The Town NFT(s) have access to read Apes Out On The Town."
          image={ApeOpenComic}
          imgClassName="opencomicImage"
          alt="ape open comic"
          size="large"
        />
        {!isEventClosed && (
          <DescriptionSection
            title="Claim Your Ape Madness NFT"
            content="To celebrate a milestone in collaborative storytelling across NFT communities, all original Ape Madness tournament entrants may now mint a commemorative NFT illustrated by Chris Wahl."
            buttonText={account ? "Claim Your NFT" : "Connect Wallet"}
            buttonClassName="btn-primary-green"
            buttonHandler={async () => await handleClaimApeMadness()}
            isConnectBtn={account ? false : true}
            image={ApeMadness}
            alt="ape madness"
            isReversedColumn={true}
            containerBgImage={true}
          />
        )}
        {BurnModalsComponent(burnStatus)}
        {ClaimModalsComponent(claimStatus)}
      </main>
      <FooterSection />
    </>
  );
};

export default ApeTownPage;
