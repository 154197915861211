import "./PhaseComponent.css";

const PhaseComponent: React.FC = () => {
    const phaseQuery = [
        {
            title: "Phase 1: Genesis Assets",
            items: {
                "PUNKS Issue 1": "1 mint",
                "Founder's DAO": " 1 mint",
                "MintPass 1": "1 mint",
                "MetaHero Identity": "1 mint",
                "Full Genesis Set": "+1 mint",
                "PUNKS Issue 1 (Special Edition)": "+2 mints",
                "Core MetaHero Identity": "+2 mints",
                "Full Planet Set": "2 mints",
            },
            starts: "Starts At 11 AM ET",

            duration: "2 Hours",
        },
        {
            title: "Phase 2A: Planets",
            items: {
                "Planet (Mercury)": "1 mint",
                "Planet (Venus)": "1 mint",
                "Planet (Earth)": "1 mint",
                "Planet (Moon)": "1 mint",
                "Planet (Mars)": "1 mint",
            },
            starts: "Starts At 1 PM ET",

            duration: "2 Hours",
        },
        {
            title: "Phase 2B: Planets",
            items: {
                "Planet (Jupiter)": "1 mint",
                "Planet (Saturn)": "1 mint",
                "Planet (Uranus)": "1 mint",
                "Planet (Neptune)": "1 mint",
                "Planet (Pluto)": "1 mint",
            },
            starts: "Starts At 3 PM ET",

            duration: "2 Hours",
        },
        {
            title: "Phase 3: PUNKS Brand",
            items: {
                "PUNKS Issue 2": "1 mint",
                "PUNKS Issue 2 (Elite Apes)": "1 mint",
                "Origin Stories 1": "1 mint",
                "Origin Stories 2": "1 mint",
            },
            starts: "Starts At 5 PM ET",

            duration: "2 Hours",
        },
        // {
        //     title: "Phase 4:",
        //     items: {
        //         "MintPass 2": "1 mint",
        //     },
        //     // starts: "Starts At 5 PM ET",
        //     duration: "1 Hour",
        // },
        // {
        //     title: "Emergency Access 5:",
        //     items: {
        //         "Adidas ITM": "1 mint",
        //     },
        //     // starts: "Starts At 5 PM ET",
        //     duration: "1 Hour",
        // },
        // {
        //     title: "Emergency Access 6:",
        //     items: {
        //         "Public Access": "max 20/token",
        //     },
        //     // starts: "Starts At 5 PM ET",
        //     duration: "Until sell out",
        // },
    ];

    const phaseActiveStyle = {
        color: "#ffffff",
    } as React.CSSProperties;

    return (
        <div className="phases">
            <div className="content-title">Minting Phases</div>
            <div className="phase-content">
                {phaseQuery.map(phase => (
                    <div style={phaseActiveStyle} className="phase-container">
                        <div className="phase-title">{phase.title}</div>

                        <p className="phase-text">Mints Per Nft/Set Held:</p>

                        <ul>
                            {Object.entries(phase.items).map(([key, value]) => {
                                return (
                                    <li className="phase-items">
                                        <span>{key}</span>
                                        <span>{value}</span>
                                    </li>
                                );
                            })}
                        </ul>

                        <div className="countdown-column">
                            <div className="countdown-row">
                                <p className="phase-duration">{phase.duration}</p>
                                <p className="phase-countdown">{phase.starts}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default PhaseComponent;
