import React, { ReactElement, useEffect } from "react";

import FooterSection from "components/FooterSection/FooterSection";
import PdfViewer from "components/PdfViewer/PdfViewer";

import styles from "../ReadOS3Comic.module.scss";

type PublicReadComponentProps = {
  title: string;
  issuuLink: string;
};

const PublicReadComponent = (props: PublicReadComponentProps): ReactElement => {
  const { title, issuuLink } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <main>
        <div>
          <h1 className={styles.originStoriesHeader}>{title}</h1>
          <PdfViewer showTitle={false} pdfUrl={issuuLink} />
        </div>
      </main>
      <FooterSection />
    </>
  );
};

export default PublicReadComponent;
